import { RoutingFormProps } from "@/features/item/components/form/ItemsCreateForm";
import { RoutingBomRow } from "@/features/item/components/form/itemsStepperForm/routingsBomList/RoutingBomRow";
import { RoutingRow } from "@/features/item/components/form/itemsStepperForm/routingsBomList/RoutingRow";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Table, Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";

const Thead = styled.thead`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
const Tr = styled.tr`
  display: flex;
  width: 100%;
  min-width: 818px;
  align-items: flex-start;
  align-self: stretch;
`;

const Th = styled.th<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const RoutingListContainer = styled.div`
  width: 75rem;
`;

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const ItemTabBodyContainer = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

const RoutingTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const RoutingBomList = (params: {
  formData: Partial<RoutingFormProps>;
}) => {
  const form = params.formData;

  const rows = form.routingRows;

  return (
    <RoutingListContainer>
      <ItemTabBodyContainer>
        <RoutingTitle>
          <Text fz="xl" fw={500}>
            라우팅
          </Text>
          <Text fz="xs" fw={400}>
            품목을 생산하기 위한 라우팅과 라우팅에 필요한 BOM들을 보여줍니다.
          </Text>
        </RoutingTitle>
        <Table>
          <Thead>
            <Tr>
              <Th width={4}>&nbsp;</Th>
              <Th width={9}>시퀀스</Th>
              <Th width={11}>공정명</Th>
              <Th width={13}>라우팅코드</Th>
              <Th width={9}>라우팅타입</Th>
              <Th width={9}>소모 시점</Th>
              <Th width={45}>금형</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rows &&
              rows.map(
                (
                  data: ItemsGet200ResponseRowsInnerRoutingsInner,
                  i: number
                ) => {
                  return (
                    data && (
                      <RoutingRow data={data} key={data.id}>
                        {data?.routingBoms?.length &&
                          Object.keys(data.routingBoms[0]).length && (
                            <RoutingBomRow
                              routingBoms={data.routingBoms ?? []}
                            />
                          )}
                      </RoutingRow>
                    )
                  );
                }
              )}
          </Tbody>
        </Table>
      </ItemTabBodyContainer>
    </RoutingListContainer>
  );
};
