import { routingOutsources } from "@/api/routingOutsource/useRoutingOutsourcesQuery";
import { RoutingOutsourceDetailHeader } from "@/features/RoutingOutsource/header/DetailHeader";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useQuery } from "react-query";

const Detail = Object.assign(Main, {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
})

interface RoutingOutsourceDetailFormProps {
    name: string | undefined;
}

export const RoutingOutsourceDetailForm = (params: RoutingOutsourceDetailFormProps) => {
    const { name } = params;

    const { data: routingOutsourceData } = useQuery(routingOutsources.get({
        query: { $and: [{ "name": { "$eq": name } }] },
        populate: ["location"]
    }));

    return (
        <>
            <Detail.Container>
                <Detail.Wrapper>
                    <Detail.Content>
                        <RoutingOutsourceDetailHeader data={routingOutsourceData} />
                    </Detail.Content>
                </Detail.Wrapper>
            </Detail.Container>
        </>
    )
}