import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import styled from "@emotion/styled";
import { Radio } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { ChangeEventHandler } from "react";

export function RoutingsListTableRow({
  data,
  checkValue,
  onhandleChecked,
}: {
  data: ItemsGet200ResponseRowsInnerRoutingsInner;
  checkValue: any;
  onhandleChecked: any;
}) {
  const handleCheckRadio: ChangeEventHandler<HTMLInputElement> = (e) => {
    onhandleChecked(e.target.value);
  };
  return (
    <>
      <Tr id={data.itemCode}>
        <Td width={12}>
          <Radio
            label={data.seq}
            name="routing"
            value={data.seq}
            checked={checkValue === data.seq}
            onChange={(e) => handleCheckRadio(e)}
          />
        </Td>
        <Td width={12}>{data.operationCode}</Td>
        <Td width={18}>{data.code}</Td>
        <Td width={12}><DetailBadge>{data.routingType}</DetailBadge></Td>
        <Td width={12}><DetailBadge>{data.consumeType}</DetailBadge></Td>
        <Td width={32}>{data.moldCode}</Td>
      </Tr>
    </>
  );
}

const Tr = styled.tr`
  display: flex;
  width: 100%;
`;

const Td = styled.td<{ width: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  gap: 10px;
  width: ${(props) => props.width}%;
`;
