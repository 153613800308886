import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { inventories, mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { Button, Select, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, DefaultApiWmsOutgoingPostRequest, ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner, WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Date: DateInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    ItemAutoComplete: ItemAutoComplete,
});

export const DeliveryForm = (params: { formatterProps: AuthSignupPost201ResponseEquipmentPurchaseOrderItem }) => {
    const { closeModal } = useModal();
    const { formatterProps } = params;
    // const { mutate: updateMutate } = useInventoryMutation("outgoing");
    const queryClient = useQueryClient();
    const { mutate: outgoingMutate } = useMutation(
        (params: DefaultApiWmsOutgoingPostRequest) =>
            mutateInventories.outgoing(params).mutationFn(params as DefaultApiWmsOutgoingPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("inventories");
            }
        }
    )

    const [selectLot, setSelectLot] = useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner>();
    const { data: inventoryData } = useQuery(inventories.find({
        query: {
            $and: [
                { itemCode: { $eq: formatterProps.itemCode } }
            ]
        },
        populate: ["lot", "location", "item"],
    }))

    const form = useForm<Partial<WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner>>({
        initialValues: {
            itemCode: undefined,
            locationCode: undefined,
            quantity: undefined,
            lotId: null,
            lotInfo: {
                name: null,
                expiration: null,
            },
            purchaseOrderItemId: undefined,
        },
    });

    const onSubmit = () => {
        try {
            const { itemCode, locationCode, quantity } = form.values;
            if (!itemCode && !locationCode && !quantity) return;

            outgoingMutate(
                {
                    workLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner: {
                        itemCode:
                            formatterProps.itemCode ?? '',
                        locationCode:
                            selectLot?.locationCode ?? '',
                        quantity: form.values.quantity ? form.values.quantity : selectLot?.quantity || form.values.quantity as string,
                        lotId: Number(selectLot?.lotId || form.values.lotId),
                        lotInfo: {
                            name:
                                selectLot?.lot?.name,
                            expiration:
                                selectLot?.lot?.expiration,
                        },
                        purchaseOrderItemId: formatterProps.id,
                        actionFrom: 'WEB'
                    },
                },
                {
                    onSuccess: (res: { status: number }) => {
                        closeModal(form.values);
                        if (res.status === 200) {
                            customAlert(
                                "재고 출고에 성공하였습니다.",
                                "재고 출고 성공",
                                "green"
                            )
                        } else {
                            customAlert(
                                "재고 출고에 실패하였습니다.",
                                "재고 출고 실패",
                                "red"
                            )
                        }
                    },
                }
            );
        } catch (e) {
            customAlert(
                "재고 출고에 실패하였습니다.",
                "재고 출고 실패",
                "red"
            )
        }
    };

    return (
        <>
            <Create.Wrapper>
                <Create.Input
                    disabled
                    label="품목코드"
                    withAsterisk
                    {...form.getInputProps("itemCode")}
                    defaultValue={formatterProps?.itemName}
                />
                <Select
                    label="LOT 정보"
                    clearable
                    data={inventoryData?.data? inventoryData.data
                        .filter((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => item?.quantity && item?.quantity > "0") // 수량이 존재하고 0보다 큰 항목만 필터링
                        .map((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => {
                          return {
                            value: item.lotId ? item.lotId.toString() : '', // value를 string으로 변환
                            label: `이름 : ${item?.lot?.name ?? ''} 유효기한 : ${item?.lot?.expiration ?? ''} 로케이션코드 : ${item?.locationCode} 수량 : ${item?.quantity}`
                          }
                        }) : []}
                    onChange={(e) => {
                        const selectedLotId = Number(e);
                        const matchedItem = inventoryData?.data?.find((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => item.lotId === selectedLotId);
                        setSelectLot(matchedItem);
                    }}
                />
                <Create.Input
                    disabled
                    label="로케이션코드"
                    withAsterisk
                    {...form.getInputProps("locationCode")}
                    defaultValue={selectLot?.locationCode}
                />
                <Create.Input
                    label="수량"
                    withAsterisk
                    type="number"
                    {...form.getInputProps("quantity")}
                    defaultValue={selectLot?.quantity}
                />
                <Create.Input
                    disabled
                    label="만료일"
                    {...form.getInputProps("lotInfo.expiration")}
                    defaultValue={selectLot?.lot?.expiration}
                />
                <Create.Input
                    label="수주 정보"
                    {...form.getInputProps("purchaseOrderItemId")}
                    defaultValue={formatterProps?.contractorName}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>
                        취소
                    </Create.Button>
                    <Create.Button onClick={onSubmit}>재고 출고</Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    );
};
