import { RoutingFormProps } from "@/features/item/components/form/ItemsCreateForm";
import { RoutingBomForm } from "@/features/item/components/form/itemsStepperForm/routingsList/RoutingBomForm";
import { RoutingsListTableRow } from "@/features/item/components/form/itemsStepperForm/routingsList/RoutingsListTableRow";
import styled from "@emotion/styled";
import { Table } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { ItemsGet200ResponseRowsInnerRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useState } from "react";

export const RoutingsList = (params: {
  formData: UseFormReturnType<Partial<RoutingFormProps>>;
}) => {
  const form = params.formData;
  const rows = form.values.routingRows ?? [];

  const [checkValue, setCheckValue] = useState<string | null>(
    rows[0]?.seq?.toString() ?? null
  );
  const [index, setIndex] = useState<number>(0);

  // check된 row의 id값을 가져옴, idx 값도 동일하게 가져온다.
  const handleChecked = (value: string, i: number) => {
    setCheckValue(value);
    setIndex(i);
  };

  return (
    <TableContainer>
      <TableWrapper>
        <Thead>
          <Tr>
            <Th width={12}>시퀀스</Th>
            <Th width={12}>공정</Th>
            <Th width={18}>라우팅코드</Th>
            <Th width={12}>라우팅타입</Th>
            <Th width={12}>소모 시점</Th>
            <Th width={32}>금형</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows &&
            rows.map(
              (
                data: ItemsGet200ResponseRowsInnerRoutingsInner,
                i: number
              ) => {
                return (
                  <RoutingsListTableRow
                    data={data}
                    key={data.seq}
                    checkValue={checkValue}
                    onhandleChecked={(value: string) => handleChecked(value, i)}
                  />
                );
              }
            )}
        </Tbody>
      </TableWrapper>
      <RoutingBomForm
        formData={form}
        checkValue={checkValue}
        formIndex={index}
      />
    </TableContainer>
  );
};
// const Table = styled.table`
//   width: 100%;
//   min-height: 600px;
// `;

export const TableContainer = styled.section`
  display: flex;
  width: 100%;
  min-height: 250px;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

export const TableWrapper = styled(Table)`
  display: flex;
  flex-direction: column;
  width: 59%;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;

const Thead = styled.thead`
  width: 100%;
`;

const Tbody = styled.tbody`
  width: 100%;
`;

const Tr = styled.tr`
  display: flex;
  width: 100%;
`;

const Th = styled.th<{ width: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  gap: 10px;
  width: ${(props) => props.width}%;
`;
