import DefaultInstance from "@/instance/axios";
import { RoutingOutsourcesGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

export type RoutingOutsourceOption = {
  value: string;
  label: string;
  group: string;
};

const getAutoCompleteKeyword = () =>
  DefaultInstance.routingOutsourcesFindPost().then(
    (res: AxiosResponse<RoutingOutsourcesGet200ResponseRowsInner[]>) => res.data
  );

export const useRoutingOutsourcesAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: RoutingOutsourceOption[]) => void;
  }
) => {
  return useQuery(
    ["routingCode-get-autocomplete"],
    () => getAutoCompleteKeyword(),
    {
      enabled: focus,
      select: (data: RoutingOutsourcesGet200ResponseRowsInner[]) =>
        data?.map((routingOutsource) => {
          return {
            value: String(routingOutsource.id),
            label: String(routingOutsource.name),
            group: String(routingOutsource.routingCode),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};

const getRoutingOutsourcesByCode = (routingCode: string | null) =>
  DefaultInstance.routingOutsourcesFindPost({
    ...(routingCode && { search: routingCode }),
    searchFields: ["name"],
  }).then((res: AxiosResponse<RoutingOutsourcesGet200ResponseRowsInner[]>) => res.data);

export const useRoutingOutsourcesCode = (
  enabled: boolean,
  routingCode: string | null,
  options?: {
    onSuccess: (data?: RoutingOutsourceOption[]) => void;
  }
) => {
  return useQuery(["routingOutsource-get-code", routingCode], () => getRoutingOutsourcesByCode(routingCode), {
    enabled: enabled ?? false,
    select: (data: RoutingOutsourcesGet200ResponseRowsInner[]) =>
      data?.map((routingOutsource) => {
        return {
          value: String(routingOutsource.id),
          label: String(routingOutsource.name),
          group: String(routingOutsource.routingCode),
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
