import {
    MantineReactTable,
    useMantineReactTable,
    // createRow,
    type MRT_ColumnDef
} from 'mantine-react-table';
import { useMemo } from 'react';

import { ItemsDetailForm } from '@/features/item/components/form/ItemsDetailForm';
import { useModal } from '@/features/modal/ModalStackManager';
import { MoldDetailForm } from '@/features/mold/form/moldDetailForm';
import { OperationDetailForm } from '@/features/operations/components/form/operationDetailForm';
import { RoutingDetailForm } from '@/features/routing/components/form/RoutingDetailForm';
import { DetailLink } from '@/features/ui/detail/DetailLink';
import { Badge, DefaultMantineColor, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ProductionPlanFormProps } from '../../view';

export interface Row {
    routingData: {
        id: number | any;
        moldCode: string | any;
        operationCode: string | any;
        seq: number | any;
        itemCode: string | any;
        code: string | any;
        routingType: string | any;
        consumeType: string | any;
        operation: {
            name: string | any;
        }
    };
}

export const RoutingTabs = ({ data }: ProductionPlanFormProps) => {

    const { openModal } = useModal();

    const { t } = useTranslation();

    const routingTypeColor = {
        "NORMAL": "green.7",
        "ASSEMBLE": "blue.7",
        "EXTERNAL": "yellow.7",
    }

    const columns = useMemo<MRT_ColumnDef<Row>[]>(
        () => [
            {
                accessorFn: (row) =>
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <ItemsDetailForm itemCode={row.routingData.itemCode} />,
                                null,
                                "품목 상세"
                            )
                        }}
                        justify="flex-start"
                    >
                        {row.routingData.itemCode}
                    </DetailLink>,
                accessorKey: 'itemCode',
                header: '품목코드',
                size: 80,
            },
            {
                accessorFn: (row) => row.routingData.operation.name,
                accessorKey: 'operationCode',
                header: '공정명',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    return (
                        <DetailLink
                            onClick={() => {
                                openModal(
                                    <OperationDetailForm operationCode={rows.row._valuesCache.operationCode} />,
                                    null,
                                    ""
                                )
                            }}
                            justify="flex-start"
                        >
                            {rows.row.original.routingData.operation.name}
                        </DetailLink>
                    )
                }
            },
            {
                accessorFn: (row) => row.routingData.code,
                accessorKey: 'routingCode',
                header: '라우팅코드',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    return (
                        <DetailLink
                            onClick={() => openModal(<RoutingDetailForm routingCode={rows.row.original.routingData.code} />, null, "")}
                            justify="flex-start"
                        >
                            {rows.row.original.routingData?.code}
                        </DetailLink>
                    )
                },
            },
            {
                accessorFn: (row) => t(row.routingData.routingType),
                accessorKey: 'routingType',
                header: '라우팅타입',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    const routingType = rows.row._valuesCache.routingType as keyof typeof routingTypeColor;

                    return (
                        <Badge color={routingTypeColor[routingType] as DefaultMantineColor}>
                            {t(rows.row._valuesCache.routingType)}
                        </Badge>
                    )
                }
            },
            {
                accessorFn: (row) => row.routingData.moldCode,
                accessorKey: 'moldCode',
                header: '사용 금형',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    return (
                        <Flex direction="column">
                            {rows.row._valuesCache.moldCode && <Badge size="sm" w={40}>금형</Badge>}
                            <DetailLink
                                onClick={() => openModal(
                                    <MoldDetailForm moldCode={rows.row._valuesCache.moldCode} />,
                                    null,
                                    ""
                                )}
                                justify="flex-start"
                            >
                                {rows.row._valuesCache.moldCode}
                            </DetailLink>
                        </Flex>
                    )
                }
            },
            {
                accessorFn: (row) => t(row.routingData.consumeType),
                accessorKey: 'consumeType',
                header: '소모 시점',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
            },
            {
                accessorFn: (row) => row.routingData.seq,
                accessorKey: 'seq',
                header: '라우팅순서',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    return (
                        <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.seq}</div>
                    )
                }
            },
        ],
        [],
    );

    const worksData: Row[] = data && Array.isArray(data.works) ? data.works.map(row => {
        if (Array.isArray(row) && row.length > 0) {
            return {
                ...row,
            };
        } else {
            return row;
        }
    }) as Row[] : [];

    const table = useMantineReactTable({
        columns,
        data: worksData,
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: false,
        enableToolbarInternalActions: false, // 우측상단 아이콘 안보이게
        enablePagination: false,
    });

    return <MantineReactTable table={table} />;
};
