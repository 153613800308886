import {
    MantineReactTable,
    useMantineReactTable,
    // createRow,
    type MRT_ColumnDef
} from 'mantine-react-table';
import { useMemo } from 'react';

import { ItemsDetailForm } from '@/features/item/components/form/ItemsDetailForm';
import { useModal } from '@/features/modal/ModalStackManager';
import { DetailLink } from '@/features/ui/detail/DetailLink';
import timeUtil from '@/utils/timeUtil';
import { setToLocaleString } from '@/utils/unitMark';
import { Checkbox } from '@mantine/core';
import dayjs from 'dayjs';
import { DetailLocationInformProps } from '../detail/LocationDetailInForm';

export interface Row {
    itemCode: string | any;
    id: number | any;
    itemUsesLotExpiration: boolean | any;
    itemUsesLotName: boolean | any;
    lot: {
        expiration: string | any;
        name: string | any;
    },
    lotName: string;
    lotExpiration: string;
    quantity: number | any;
    createdAt: string | any;
    unitText: string | any;
}

export const LocationLedgerTabs = ({ data }: DetailLocationInformProps) => {

    const rows: Row[] = data?.data ?? [];

    const { openModal } = useModal();

    const columns = useMemo<MRT_ColumnDef<Row>[]>(
        () => [
            {
                accessorFn: (row) => row.id,
                accessorKey: 'id',
                header: '품목 번호',
                size: 80,
                Cell: (rows) => {
                    return (
                        <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.id}</div>
                    )
                }
            },
            {
                accessorFn: (row) =>
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <ItemsDetailForm itemCode={row.itemCode} />,
                                null,
                                "품목 상세"
                            )
                        }}
                        justify={"flex-start"}
                    >
                        {row.itemCode}
                    </DetailLink>,
                accessorKey: 'itemCode',
                header: '품목코드',
                size: 80,
            },
            {
                accessorFn: (row) => [
                    <div><Checkbox label="이름" checked={row.itemUsesLotName} mb={8} disabled /></div>,
                    <div><Checkbox label="유효기한" checked={row.itemUsesLotExpiration} disabled /></div>
                ],
                accessorKey: 'itemUses',
                header: '로트관리 기준',
                size: 120,
            },
            {
                accessorFn: (row) => [
                    <div>이름 : {row.lotName}</div>,
                    <div>유효기한 : {timeUtil(row.lotExpiration === null ? '' : row.lotExpiration) === 'NaN-NaN-NaN' ? '' : timeUtil(row.lotExpiration === null ? '' : row.lotExpiration)}</div>
                ],
                accessorKey: 'lot',
                header: '로트 정보',
                size: 160,
            },
            {
                accessorFn: (row) => row.quantity,
                accessorKey: 'quantity',
                header: '수량',
                size: 80,
                Cell: (rows) => {
                    const quantity = setToLocaleString(rows.row._valuesCache.quantity) === "NaN" ? '0' : setToLocaleString(rows.row._valuesCache.quantity);
                    const unitText = rows.row.original.unitText;
                    return (
                        <div style={{ textAlign: 'right' }}>{quantity} {unitText}</div>
                    )
                }
            },
            {
                accessorFn: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                accessorKey: 'createdAt',
                header: '생성일',
                size: 200,
            }

        ],
        [],
    );

    const table = useMantineReactTable({
        columns,
        data: rows,
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: false,
        enableToolbarInternalActions: false, // 우측상단 아이콘 안보이게
        enablePagination: false,
    });

    return <MantineReactTable table={table} />;
};
