import styled from "@emotion/styled";
import { Button, Select, Table, TextInput } from "@mantine/core";
import { useState } from "react";

import { IconCalendar, IconPlus } from "@tabler/icons-react";

import { useModal } from "@/hooks";
import { theme } from "@/styles/theme";
import dayjs from "dayjs";

import useGetEquipmentsQuery from "@/api/equipment/useGetEquipmentQuery";
import { operations } from "@/api/operations/useOperationsQuery";
import usePlansWithWorksMutation from "@/api/usePlansWithWorksMutations";
import { ProductionPlanInput } from "@/features/ui/modal/CreatePlanningModal";
import { Item } from "@/pages/item";
import { useQuery } from "react-query";

export const PlanningTable = ({
  type,
  data: routingInputs,
}: {
  type: "equipment" | "plan";
  data: Array<any & ProductionPlanInput>;
}) => {
  const [productOpertaion, setProductOpertaion] = useState<
    Array<any & ProductionPlanInput>
  >([...routingInputs]);

  const { data: equipments } = useGetEquipmentsQuery();
  const { data: operation } = useQuery(operations.get({}));
  const { mutate } = usePlansWithWorksMutation();
  const { closeModal } = useModal();

  const handleClickAdd = () => {
    setProductOpertaion((prev: ProductionPlanInput & any) => [
      ...prev,
      {
        date: routingInputs[0].date,
        routingCode: "",
        equipmentCode: "",
        targetQuantity: routingInputs[0].targetQuantity,
        item: Item,
        isItemRouting: false,
      },
    ]);
  };

  const onChageProductRoutings = (
    index: number,
    cell: { id: string; value: string }
  ) => {
    const list = [...productOpertaion] as any;
    list[index][cell.id] = cell.value;
    setProductOpertaion(list);
  };

  const onSave = () => {
    mutate({
      itemCode: productOpertaion[0].product, // item.code
      scheduledAt: dayjs(productOpertaion[0].date).toISOString(),
      targetQuantity: productOpertaion[0].targetQuantity,
      works: productOpertaion.map((routing) => ({
        routingCode: routing.routingCode,
        equipmentCode: routing.equipmentCode,
      })),
    });
    closeModal();
  };

  return (
    <>
      <TableContainer>
        <thead>
          <tr>
            <th>일자</th>
            <th>라우팅</th>
            <th>설비</th>
            <th>목표수량</th>
          </tr>
        </thead>
        <tbody>
          {productOpertaion.map((routing, index) => (
            <>
              <Tr key={index}>
                <td>
                  <TextInput
                    id="date"
                    value={dayjs(routing.date).format("YYYY-MM-DD")}
                    icon={<IconCalendar size="1.2rem" />}
                    disabled
                    size="md"
                  />
                </td>
                <td>
                  <Select
                    disabled={routing.isItemRouting}
                    id={"routingCode"}
                    value={routing.routingCode}
                    size="md"
                    onChange={(value) =>
                      onChageProductRoutings(index, {
                        id: "routingCode",
                        value,
                      } as any)
                    }
                    data={
                      routing.isItemRouting
                        ? [routing.routingCode]
                        : operation?.data?.rows?.map((row) => row.code) ?? []
                    }
                  />
                </td>
                <td>
                  <Select
                    id={"equipmentCode"}
                    size="md"
                    onChange={(value) =>
                      onChageProductRoutings(index, {
                        id: "equipmentCode",
                        value,
                      } as any)
                    }
                    data={equipments?.data.rows?.map((row) => row.code) ?? []}
                  />
                </td>
                <td>
                  <TextInput
                    id="targetQuantity"
                    value={routing.targetQuantity}
                    disabled
                    size="md"
                  />
                </td>
              </Tr>
              {productOpertaion.length - 1 === index &&
                productOpertaion.length <= 10 && (
                  <AddButtonTr>
                    <AddButton leftIcon={<IconPlus />} onClick={handleClickAdd}>
                      작업 지시 추가
                    </AddButton>
                  </AddButtonTr>
                )}
            </>
          ))}
        </tbody>
      </TableContainer>
      <SaveButton onClick={onSave}>저장</SaveButton>
    </>
  );
};

const TableContainer = styled<any>(Table)`
  height: 256px;
`;

const AddButton = styled<any>(Button)`
  width: 100%;
  background-color: #fff;
  color: black;
  top: 50%;
  left: 150%;
  border-radius: 4px;
  margin: 4px 0;
  height: 32px;
  font-size: 14px;

  &:hover {
    background-color: ${theme.colors?.blue?.[0]};
  }
`;

const AddButtonTr = styled.tr`
  width: 100%;
`;

const Tr = styled.tr`
  width: 100%;
  margin-top: 4px;
`;

const SaveButton = styled<any>(Button)`
  margin-top: 8px;
  width: 100%;
  color: ${theme.colors?.blue?.[6]};
  background-color: ${theme.colors?.white};
  border: 1px solid ${theme.colors?.blue?.[6]};
  height: 32px;
  font-size: 14px;

  &:hover {
    background-color: ${theme.colors?.blue?.[0]};
  }
`;
