import { ActionIcon, Badge, Flex, Tooltip } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import {
  MantineReactTable,
  useMantineReactTable,
  // createRow,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { useMemo } from "react";

import { specifications } from "@/api/inspect/inspectSpecification/useSpecificationsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { RoutingDetailForm } from "@/features/routing/components/form/RoutingDetailForm";
import { SpecificationsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "react-query";

export const InspectSpecificationsByItemTable = (params: {
  itemCode: string | undefined;
}) => {
  const { itemCode } = params;

  const { data: spectification } = useQuery(specifications.get({
    query: {
      $and: [
        {
          itemCode: itemCode,
        },
      ],
    },
  }));

  const { openModal } = useModal();

  const rows: SpecificationsGet200ResponseRowsInner[] =
    spectification?.data.rows ?? [];

  const columns = useMemo<
    MRT_ColumnDef<SpecificationsGet200ResponseRowsInner>[]
  >(
    () => [
      {
        // 1
        accessorKey: "itemCode",
        header: "품목코드",
        size: 80,
      },
      {
        accessorKey: "routingCode",
        header: "검사종류",
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <Badge color="blue" radius={2} fw={700} size={"9px"}>
              초중종물 검사
            </Badge>
          );
        },
      },
      {
        accessorKey: "summary",
        header: "검사 기준 요약",
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          const summaryNames = rows.row.original.criteria.map(
            (data: any) => data.name
          );
          return (
            <>
              {/* {summaryNames.join(", ")} */}
              {summaryNames.length > 1
                ? summaryNames[0] + " 외 " + (summaryNames.length - 1) + "개"
                : summaryNames[0]}
            </>
          );
        },
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: rows as SpecificationsGet200ResponseRowsInner[],
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enableToolbarInternalActions: false, // 우측상단 아이콘 안보이게
    enablePagination: false,
    positionActionsColumn: "last",

    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="라우팅 상세정보">
          <ActionIcon
            onClick={() =>
              openModal(
                <RoutingDetailForm routingCode={row.original.routingCode} />,
                "",
                "라우팅 상세정보"
              )
            }
          >
            <IconSearch />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  });

  return <MantineReactTable table={table} />;
};
