import { BASE_URL } from "@/instance/axios";
import axios from "axios";
import { useQuery } from "react-query";

// routings와 routing 나중에 병합할 예정
export const QUERY_KEY = "/routing";

interface GetRoutingFetchParams {
  itemCode?: string;
  quantity: string;
}

const getItemRoutingsFetch = ({
  itemCode,
  quantity,
}: GetRoutingFetchParams) => {
  return axios.post(
    `${BASE_URL}/routings/byItem`,
    {
      itemCode: itemCode,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      },
    }
  );
};

const useGetItemRoutingsQuery = ({
  itemCode,
  quantity,
}: GetRoutingFetchParams) => {
  return useQuery<any>({
    queryKey: [QUERY_KEY, itemCode],
    queryFn: () => getItemRoutingsFetch({ itemCode, quantity }),
    enabled: !!itemCode,
  });
};

export default useGetItemRoutingsQuery;
