import { consumeType, routingType } from "@/constants/routings";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { OperationAutoComplete } from "@/features/ui/autoComplete/operation/operation-autoComplete";
import { Box, Button, Code, Select, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";

export function RoutingForm(params: {
  code: string;
  onSubmit?: (values: any) => void;
}) {
  const { t } = useTranslation();
  const lnRoutingType = routingType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const lnConsumeType = consumeType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const { code, onSubmit } = params;
  const form = useForm({
    initialValues: {
      itemCode: undefined,
      code: code,
      routingType: undefined,
      consumeType: undefined,
      operationCode: undefined,
      seq: undefined,
    },
  });

  return (
    <Box maw={320} mx="auto" w={400}>
      <form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
        <ItemAutoComplete
          label="품목코드"
          {...form.getInputProps("itemCode")}
        />
        <OperationAutoComplete
          label="공정코드"
          {...form.getInputProps("operationCode")}
        />
        <TextInput label="라우팅코드" {...form.getInputProps("code")} />
        <Select
          label="라우팅유형"
          data={lnRoutingType}
          {...form.getInputProps("routingType")}
        />
        <Select
          label="소모 시점"
          data={lnConsumeType}
          {...form.getInputProps("consumeType")}
        />
        <TextInput
          label="라우팅순서"
          type="number"
          {...form.getInputProps("seq")}
        />
        <Text size="sm" weight={500} mt="xl">
          Form values:
        </Text>
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
        <Button
          type="submit"
          disabled={
            !form.values.code ||
            !form.values.itemCode ||
            !form.values.operationCode ||
            !form.values.routingType ||
            !form.values.consumeType ||
            !form.values.seq
          }
        >
          Submit
        </Button>
      </form>
    </Box>
  );
}
