import useGenerateDraftWorksQuery from "@/api/useGenerateDraftWorks";
import usePlansWithWorksMutation from "@/api/usePlansWithWorksMutations";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { PurchaseOrderItemsAutoComplete } from "@/features/ui/autoComplete/purchaseOrderItems/purchaseOrderItems-autoComplete";
import { RoutingOutsourceAutoComplete } from "@/features/ui/autoComplete/routingOutsources/routingOutsources-autoComplete";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { setDateFormatString } from "@/utils/dateTimeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import styled from "@emotion/styled";
import {
  Button,
  Flex,
  Group,
  Paper,
  Space,
  Stepper,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  ProductionPlansWithWorksPostRequest,
  ProductionPlansWithWorksPostRequestWorksInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";

// interface ExtendedWorks extends ProductionPlansWithWorksPostRequestWorksInner {
//   routingOutsourceName: string | null | undefined;
// }

// interface ExtendedCreateProductionPlanWithWorks
//   extends CreateProductionPlanWithWorks {
//   works: Array<ExtendedWorks>;
// }

export function ProductionPlanWithWorksForm(
  params: Readonly<{
    dateString?: string;
    siteCode?: string;
    width?: string;
    formatterProps?: any
  }>
) {
  const { dateString, siteCode, formatterProps } = params;
  const [active, setActive] = useState(0);
  const { closeModal } = useModal();

  const { mutate } = usePlansWithWorksMutation();

  const form = useForm<
    Partial<ProductionPlansWithWorksPostRequest> & {
      scheduledAtDate: Date;
      siteCode?: string;
      siteName?: string;
    }
  >({
    initialValues: {
      itemCode: formatterProps?.itemCode ?? undefined,
      scheduledAtDate: dateString
        ? dayjs(dateString).startOf("D").toDate()
        : dayjs().startOf("D").toDate(),
      targetQuantity: undefined,
      works: [],
      sub: undefined,
      siteCode: siteCode,
      siteName: undefined,
      purchaseOrderItemId: formatterProps?.id?.toString() ?? undefined,
    },
  });

  const queryDraftWorks = useGenerateDraftWorksQuery({
    itemCode: form.values.itemCode,
    targetQuantity: form.values.targetQuantity,
  });

  const { data: worksDraft } = queryDraftWorks;
  const isProductable = (worksDraft?.works || []).length !== 0;

  const nextStep = async () => {
    // worksDraft를 서버에서 얻어와야 한다.
    // productionPlansGenerateDraftWorksPost
    const { data } = await queryDraftWorks.refetch();
    // const newWorks = data?.works?.map((work) => {
    //   return {
    //     ...work,
    //     routingOutsourceName: null,
    //   };
    // });

    const newSub = data?.sub?.result?.map((sub) => {
      return {
        itemCode: sub.itemCode,
        targetQuantity: sub.targetQuantity,
        works: sub.works.map((work) => {
          return {
            routingCode: work.routingCode,
            targetQuantity: work.targetQuantity,
            moldCode: data?.sub?.commonMoldCode,
            routingOutsourceName: undefined,
          };
        }),
        scheduledAt: form.values.scheduledAtDate.toISOString(),
        purchaseOrderItemId: form.values.purchaseOrderItemId
      };
    });

    form.setFieldValue("works", data?.works);
    form.setFieldValue("sub", newSub);
    setActive((current) => current + 1);
  };
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  // 1페이지 -> 2페이지 이동 조건
  const isValidStep1 =
    { ...form.getInputProps("itemCode") }.value &&
    Number({ ...form.getInputProps("targetQuantity") }.value > 0) &&
    isProductable



  // 2페이지 -> 최종 페이지 이동 조건
  const isValidStep2: boolean =
    active === 1 &&
    Array.isArray(form.getInputProps("works").value) &&
    form.getInputProps("works").value.every((element: any) => {
      return !!element.targetQuantity && element.equipmentCode;
    }) &&
    (!form.getInputProps("sub").value?.[0] || form.getInputProps("sub").value[0].works.every((element: any) => {
      return !!element.targetQuantity && element.equipmentCode;
    }));

  const onSave = () => {
    const { itemCode, scheduledAtDate, targetQuantity, works, purchaseOrderItemId } = form.values;

    // 패밀리 금형일 경우 sub 데이터 추가
    let additionalData;
    if (worksDraft?.sub && Object.keys(worksDraft?.sub).length) {
      additionalData = {
        commonMoldCode: worksDraft?.sub?.commonMoldCode,
        sub:
          worksDraft?.sub &&
          worksDraft.sub.result.map((row) => {
            return {
              itemCode: row.itemCode,
              scheduledAt: scheduledAtDate.toISOString(),
              targetQuantity: row.targetQuantity,
              works: row.works,
              purchaseOrderItemId: purchaseOrderItemId
            };
          }),
      };
    }

    mutate(
      {
        purchaseOrderItemId: purchaseOrderItemId,
        itemCode: itemCode as string,
        scheduledAt: scheduledAtDate.toISOString(),
        targetQuantity: targetQuantity as string,
        works: works as ProductionPlansWithWorksPostRequestWorksInner[],
        ...additionalData,
      },
      {
        onSuccess: (res: any) => {
          if (res.status === 200) {
            customAlert(
              "생산계획 생성에 성공하였습니다.",
              "생성 성공",
              "green"
            );
            form.reset();
            setActive(0);
            closeModal(true);
          } else {
            customAlert("생산계획 생성에 실패하였습니다.", "생성 실패", "red");
          }
        },
      }
    );
  };

  const setSiteName = (name: string) => {
    form.setFieldValue("siteName", name);
  };

  const setName = (i: number, name: string) => {
    form.setFieldValue(`works.${i}.routingOutsourceName`, name);
  };

  const setSubName = (i: number, j: number, name: string) => {
    form.setFieldValue(`sub.${i}.works.${j}.routingOutsourceName`, name);
  };

  return (
    <FormWrapper>
      <form onSubmit={form.onSubmit((values) => console.log(values))}>
        <Stepper active={active} breakpoint="sm">
          <Stepper.Step
            label="생산계획 정보 입력"
            description="생산계획 정보를 입력합니다."
          >
            <Flex gap="lg" direction="column">
              <Flex gap="lg" justify="center" align="center">
                <DateInput
                  withAsterisk
                  popoverProps={{ withinPortal: true }}
                  icon={<IconCalendar size="1.2rem" />}
                  label="계획일자"
                  description="생산 예정일자를 선택합니다."
                  valueFormat="YYYY-MM-DD"
                  w={'100%'}
                  {...form.getInputProps("scheduledAtDate")}
                />
                <SitesAutoComplete
                  label="사업장"
                  description="생산할 사업장을 선택합니다."
                  placeholder="사업장 선택"
                  w={'100%'}
                  {...form.getInputProps("siteCode")}
                  setSiteName={(e: string) => setSiteName(e)}
                />
              </Flex>
              <Flex gap="lg" justify="center" align="center">
                <ItemAutoComplete
                  withAsterisk
                  label="품목 선택"
                  description="생산할 품목을 선택합니다."
                  placeholder="품목 선택"
                  itemType="PRODUCT,SEMI_PRODUCT"
                  w={'100%'}
                  {...form.getInputProps("itemCode")}
                />
                <TextInput
                  withAsterisk
                  type="number"
                  label="목표 수량"
                  description="생산 목표 수량을 입력합니다."
                  placeholder="목표 수량"
                  w={'100%'}
                  {...form.getInputProps("targetQuantity")}
                />
              </Flex>
              <Flex gap="lg" >
                <PurchaseOrderItemsAutoComplete
                  disabled={!!!form.values.itemCode}
                  label="납품 할 수주 선택"
                  description="납품 할 수주를 선택합니다."
                  placeholder="수주 선택"
                  w={'100%'}
                  itemCodes={form.values.itemCode}
                  {...form.getInputProps("purchaseOrderItemId")}
                />
              </Flex>
              {!isProductable &&
                form.values.itemCode &&
                form.values.targetQuantity && (
                  <WarningText>
                    품목을 생산하기 위한 라우팅이 설정되어 있지 않아 생산을 계획할
                    수 없습니다.
                  </WarningText>
                )}
            </Flex>
          </Stepper.Step>
          <Stepper.Step
            label="생성될 작업지시 확인"
            description="생성될 작업지시 확인 후 정보를 입력합니다."
          >
            <TableWrapper>
              <thead>
                <tr>
                  <th colSpan={6}>
                    <Title order={3}>{form.values.purchaseOrderItemId ? '수주 생산계획' : '기본 생산계획'}</Title>
                    <Text fz="sm">
                      생산계획 일자:{" "}
                      {setDateFormatString(form.values.scheduledAtDate)}
                    </Text>
                  </th>
                </tr>
                <tr>
                  <Th width={20}>일자</Th>
                  <Th width={10}>품목코드</Th>
                  <Th width={10}>라우팅코드</Th>
                  <Th width={25}>
                    설비 <span style={{ color: 'red' }}>*</span>
                  </Th>
                  <Th width={25}>외주처</Th>
                  <Th width={10}>목표수량</Th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(form.values.works) &&
                  form.values.works?.map((work, i) => {
                    return (
                      <tr key={i}>
                        <Td width={20}>
                          <DateInput
                            popoverProps={{ withinPortal: true }}
                            icon={<IconCalendar size="1.2rem" />}
                            valueFormat="YYYY-MM-DD"
                            placeholder={setDateFormatString(
                              form.values.scheduledAtDate
                            )}
                            clearable
                            {...form.getInputProps(`works.${i}.scheduledAt`)}
                          />
                        </Td>
                        <Td width={10}>
                          <Text>{form.values.itemCode}</Text>
                        </Td>
                        <Td width={10}>
                          <Text>{work.routingCode}</Text>
                        </Td>
                        <Td width={25}>
                          <EquipmentAutoComplete
                            placeholder="설비 선택"
                            {...form.getInputProps(`works.${i}.equipmentCode`)}
                          />
                        </Td>
                        <Td width={25}>
                          <RoutingOutsourceAutoComplete
                            placeholder="외주처 선택"
                            {...form.getInputProps(
                              `works.${i}.routingOutsourceId`
                            )}
                            setName={(e: any) => setName(i, e)}
                          />
                        </Td>
                        <Td width={10}>
                          <TextInput
                            type="number"
                            {...form.getInputProps(`works.${i}.targetQuantity`)}
                          />
                        </Td>
                      </tr>
                    );
                  })}
              </tbody>
            </TableWrapper>

            {/* 패밀리 금형일 경우 sub 데이터 추가 */}
            {Array.isArray(form.values.sub) ? (
              <TableWrapper>
                <thead style={{ width: "100%" }}>
                  <tr>
                    <Title order={3}>패밀리 금형 생산계획</Title>
                  </tr>
                  <tr>
                    <Th width={20}>일자</Th>
                    <Th width={10}>품목코드</Th>
                    <Th width={10}>라우팅코드</Th>
                    <Th width={25}>
                      설비 <span style={{ color: 'red' }}>*</span>
                    </Th>
                    <Th width={25}>외주처</Th>
                    <Th width={10}>목표수량</Th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(form.values.sub) &&
                    form.values.sub?.map((subData, i) => {
                      return subData.works.map((work, j) => {
                        return (
                          <tr key={j}>
                            <Td width={20}>
                              <DateInput
                                popoverProps={{ withinPortal: true }}
                                icon={<IconCalendar size="1.2rem" />}
                                valueFormat="YYYY-MM-DD"
                                placeholder={setDateFormatString(
                                  form.values.scheduledAtDate
                                )}
                                clearable
                                {...form.getInputProps(
                                  `sub.${i}.works.${j}.scheduledAt`
                                )}
                              />
                            </Td>
                            <Td width={10}>
                              <Text>{subData.itemCode}</Text>
                            </Td>
                            <Td width={10}>
                              <Text>{work.routingCode}</Text>
                            </Td>
                            <Td width={25}>
                              <EquipmentAutoComplete
                                placeholder="설비 선택"
                                {...form.getInputProps(
                                  `sub.${i}.works.${j}.equipmentCode`
                                )}
                              />
                            </Td>
                            <Td width={25}>
                              <RoutingOutsourceAutoComplete
                                placeholder="외주처 선택"
                                {...form.getInputProps(
                                  `sub.${i}.works.${j}.routingOutsourceId`
                                )}
                                setName={(e: any) => setSubName(i, j, e)}
                              />
                            </Td>
                            <Td width={10}>
                              <TextInput
                                type="number"
                                {...form.getInputProps(
                                  `sub.${i}.works.${j}.targetQuantity`
                                )}
                                defaultValue={subData.targetQuantity}
                              />
                            </Td>
                          </tr>
                        );
                      });
                    })}
                </tbody>
              </TableWrapper>
            ) : null}
          </Stepper.Step>

          <Stepper.Completed>
            <Paper p="md" withBorder>
              <Title order={3}>기본 생산계획</Title>
              <Title order={5}>예상되는 생산 계획</Title>
              <Table>
                <thead>
                  <tr>
                    <th>계획일자</th>
                    <th>사업장명</th>
                    <th>품목코드</th>
                    <th>목표 수량</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Text>
                        {dayjs(form.values.scheduledAt).format("YYYY-MM-DD")}
                      </Text>
                    </td>
                    <td>
                      <Text>{form.values.siteName}</Text>
                    </td>
                    <td>
                      <Text>{form.values.itemCode}</Text>
                    </td>
                    <td>
                      <Text ta="end">
                        {setToLocaleString(form.values.targetQuantity)}
                      </Text>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Space h="md" />
              <Title order={5}>예상되는 작업 지시</Title>
              <Table>
                <thead>
                  <tr>
                    <Th width={20}>일자</Th>
                    <Th width={10}>품목코드</Th>
                    <Th width={10}>라우팅코드</Th>
                    <Th width={25}>
                      설비코드 <span style={{ color: 'red' }}>*</span>
                    </Th>
                    <Th width={25}>외주처</Th>
                    <Th width={10}>목표수량</Th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(form.values.works) &&
                    form.values.works?.map((work, i) => {
                      return (
                        <tr key={i}>
                          <Td width={20}>
                            <Text>
                              {dayjs(work.scheduledAt).format("YYYY-MM-DD")}
                            </Text>
                          </Td>
                          <Td width={10}>
                            <Text>{form.values.itemCode}</Text>
                          </Td>
                          <Td width={10}>
                            <Text>{work.routingCode}</Text>
                          </Td>
                          <Td width={25}>
                            <Text>{work.equipmentCode}</Text>
                          </Td>
                          <Td width={25}>
                            <Text>{work.routingOutsourceName}</Text>
                          </Td>
                          <Td width={10}>
                            <Text ta="end">
                              {setToLocaleString(work.targetQuantity)}
                            </Text>
                          </Td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Paper>
            {Array.isArray(form.values.sub)
              ? form.values.sub.map((subData, i) => {
                return (
                  <>
                    <Space h="md" />
                    <Paper p="md" withBorder>
                      <Title order={3}>패밀리 금형 생산계획</Title>
                      <Title order={5}>예상되는 생산 계획</Title>
                      <Table>
                        <thead>
                          <tr>
                            <th>계획일자</th>
                            <th>사업장코드</th>
                            <th>품목코드</th>
                            <th>목표 수량</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Text>
                                {dayjs(subData.scheduledAt).format(
                                  "YYYY-MM-DD"
                                )}
                              </Text>
                            </td>
                            <td>
                              <Text>{form.values.siteCode}</Text>
                            </td>
                            <td>
                              <Text>{subData.itemCode}</Text>
                            </td>
                            <td>
                              <Text ta="end">
                                {setToLocaleString(subData.targetQuantity)}
                              </Text>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <Space h="md" />
                      <Title order={5}>예상되는 작업 지시</Title>
                      <Table>
                        <thead>
                          <tr>
                            <Th width={20}>일자</Th>
                            <Th width={10}>품목코드</Th>
                            <Th width={10}>라우팅코드</Th>
                            <Th width={25}>
                              설비코드 <span style={{ color: 'red' }}>*</span>
                            </Th>
                            <Th width={25}>외주처</Th>
                            <Th width={10}>목표수량</Th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(subData.works) &&
                            subData.works?.map((work, i) => {
                              return (
                                <tr key={i}>
                                  <Td width={20}>
                                    <Text>
                                      {dayjs(work.scheduledAt).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </Text>
                                  </Td>
                                  <Td width={10}>
                                    <Text>{subData.itemCode}</Text>
                                  </Td>
                                  <Td width={10}>
                                    <Text>{work.routingCode}</Text>
                                  </Td>
                                  <Td width={25}>
                                    <Text>{work.equipmentCode}</Text>
                                  </Td>
                                  <Td width={25}>
                                    <Text>{work.routingOutsourceName}</Text>
                                  </Td>
                                  <Td width={10}>
                                    <Text ta="end">
                                      {setToLocaleString(work.targetQuantity)}
                                    </Text>
                                  </Td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Paper>
                  </>
                );
              })
              : null}
          </Stepper.Completed>
        </Stepper>

        <Group position="right" mt="xl">
          {active !== 0 && (
            <Button variant="default" onClick={prevStep}>
              Back
            </Button>
          )}
          {active === 0 && (
            <Button onClick={nextStep} disabled={!isValidStep1}>
              다음: 작업지시 만들기
            </Button>
          )}
          {active === 1 && (
            <Button
              onClick={() => {
                setActive((current) => current + 1);
              }}
              disabled={!isValidStep2}
            >
              미리보기
            </Button>
          )}
          {active === 2 && <Button onClick={onSave}>생산계획 생성</Button>}
        </Group>
      </form>
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  width: 60rem;
`;

// const PlanContainer = styled.div`
//   width: 100%;
//   height: 100%;
// `;

// const PlanContent = styled.div`
//   width: 100%;
//   display: flex;
//   height: 100%;
//   justify-content: space-between;
//   align-items: center;
//   margin: 8px auto;
//   /* gap: 8px; */
// `;

// const DateLine = styled(DateInput)`
//   width: 48%;
// `;
// const TextLine = styled(TextInput)`
//   width: 48%;
// `;

const TableWrapper = styled(Table)`
  /* width: 500px; */
  /* height: 300px; */
  margin-top: 4px;
  width: 100%;
`;

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;

const Td = styled.td<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;

const WarningText = styled<any>(Text)`
  color: var(--red-6, #fa5252);
  /* components/input/error/xs */
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
`;
