import { useModal } from "@/features/modal/ModalStackManager";
import { FileImportForm } from "@/features/ui/form/file";
import { BASE_URL } from "@/instance/axios";
import { Flex, Menu, Text } from "@mantine/core";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";

type LinkType = {
    label: string;
    links?: (Link | NestedLink)[];
    link?: string;
}[];

type Link = {
    link: string;
    label: string;
    action?: boolean;
    actionKey?: 'download' | 'upload';
};

type NestedLink = {
    label: string;
    links: (Link | NestedLink)[];
    action?: boolean;
    actionKey?: 'download' | 'upload';
};

const links: LinkType = [
    { link: "/dashboard", label: "대시보드" },
    {
        label: "기준정보",
        links: [
            { link: "/items", label: "품목" },
            { link: "/equipment", label: "설비" },
            { link: "/defects", label: "불량" },
            { link: "/operations", label: "공정" },
            { link: "/routing", label: "라우팅" },
            { link: "/users", label: "사용자" },
            { link: "/site", label: "사업장" },
            { link: "/location", label: "로케이션" },
            { link: "/routing-outsource", label: "외주처" },
            { link: "/mold", label: "금형" },
            { link: "/moldStock", label: "금형재고" },
            { link: "/abstract-item", label: "품목군" },
            { link: "/item-category", label: "품목분류" },
            { link: "/downtimeReasons", label: "비가동사유" },
            { link: "/#", label: "기준정보 다운로드", action: true, actionKey: 'download' },
            { link: "/#", label: "기준정보 업로드", action: true, actionKey: 'upload' },
        ],
    },
    {
        label: "생산관리",
        links: [
            { link: "/work-by-equipment", label: "설비별 생산현황" },
            { link: "/work-by-production-plan", label: "생산계획별 생산현황" },
            { link: "/work-by-row", label: "작업지시" },
            { link: "/work-log", label: "작업일지" },
        ],
    },
    {
        label: "재고관리",
        links: [
            { link: "/inventory", label: "재고현황" },
            { link: "/ledgersAll", label: "전체 입출고 내역" },
            { link: "/wipInventory", label: "재공품 재고" },
            { link: "wmsLogs", label: "WMS 로그" }
        ],
    },
    {
        label: "영업관리",
        links: [
            { link: "/purchaseOrderItems", label: "수주현황" },
            { link: "/deliveryStatus", label: "납품현황" },
        ]
    },
    {
        label: "품질관리",
        links: [
            { link: "/inspectSpecification", label: "검사기준" },
            { link: "/inspectionRecords", label: "검사기록" },
        ]
    },
    {
        label: "현황 모니터링",
        links: [
            { link: "/overview", label: "MES 운영현황판" },
            { link: "/monitoring", label: "현황 모니터링" },
        ]
    }
];

const RenderSubMenuItems = (items: (Link | NestedLink)[]) => {

    const { openModal } = useModal();

    const handleDownLoad = () => {
        const today = new Date();
        const formattedDate = today.toLocaleDateString('ko-KR');
        // 파일 다운로드 API
        axios
            .get(`${BASE_URL}/interport/export`, {
                responseType: "blob",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `기준정보_${formattedDate}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    }

    const handleUpload = () => {
        openModal(<FileImportForm />, null, "기준정보 업로드");
    }

    return items.map((item) => {
        if ('links' in item) {
            return (
                <Menu.Item component="div" key={item.label}>
                    <Flex justify="space-between" align="center">
                        <Menu shadow="md" trigger="hover" transitionProps={{ exitDuration: 0 }} position="right" withArrow>
                            <Menu.Target>
                                <Flex justify="space-between" align="center" style={{ cursor: 'pointer', width: '100%' }}>
                                    <Text>{item.label}</Text>
                                    <IconChevronRight size="0.9rem" stroke={1.5} />
                                </Flex>
                            </Menu.Target>
                            <Menu.Dropdown>
                                {RenderSubMenuItems(item.links)}
                            </Menu.Dropdown>
                        </Menu>
                    </Flex>
                </Menu.Item>
            );
        }
        return (
            <Menu.Item
                key={item.label}
                component={RouterLink}
                to={item.action ? "#" : item.link}
                onClick={item.action ? (item.actionKey === 'download' ? handleDownLoad : item.actionKey === 'upload' ? handleUpload : undefined) : undefined}
                color={item.action ? 'indigo' : ''}
            >
                {item.label}
            </Menu.Item>
        );
    });
};

const renderMainMenuItems = (items: LinkType) => {
    return items.map((item) => (
        <Menu key={item.label} shadow="md" trigger="hover" transitionProps={{ exitDuration: 0 }} withArrow>
            <Menu.Target>
                {item.link ? (
                    <RouterLink to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Flex align="center" style={{ cursor: 'pointer' }}>
                            <Text>{item.label}</Text>
                        </Flex>
                    </RouterLink>
                ) : (
                    <Flex align="center" style={{ cursor: 'pointer' }}>
                        <Text>{item.label}</Text>
                        {item.links && <IconChevronDown size="0.9rem" stroke={1.5} />}
                    </Flex>
                )}
            </Menu.Target>
            {item.links && (
                <Menu.Dropdown>
                    {RenderSubMenuItems(item.links)}
                </Menu.Dropdown>
            )}
        </Menu>
    ));
};

export const MenuTemplate = () => {
    return (
        <Flex gap="md">
            {renderMainMenuItems(links)}
        </Flex>
    );
};
