import { equipments } from "@/api/equipment/useEquipmentQuery";
import { MonitoringBox } from "@/features/monitoring/components/parentsBox/Monitoring";
import { Parents } from "@/features/monitoring/components/parentsBox/Parents";
import { Main } from "@/features/standard/Main";
import { BASE_URL } from "@/instance/axios";
import { MultiSelect } from "@mantine/core";
import { AuthSignupPost201ResponseEquipment } from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSocket } from "socket.io-react-hook";

const Monitoring = Object.assign({}, Main, {
    Parents: Parents,
});

const createUserActionOrCondition = (selectedOptions: string[]) => {
    // 선택된 옵션이 있으면 각 옵션을 쿼리 조건으로 변환
    if (selectedOptions?.length > 0) {
        const conditions = selectedOptions.map(option => ({ $eq: option }));

        return conditions;
    }

    // 선택된 옵션이 없으면 빈 배열 반환
    return [];
};

export const MonitoringTable = () => {

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const userActionConditions = createUserActionOrCondition(selectedOptions);

    const { data: equipmentData } = useQuery(equipments?.find({
        query: {
            $and: [
                {
                    influxEquipmentCode: {
                        $and: [
                            { $not: null },
                            { $not: '' },
                        ],
                    },
                },
            ],
        },
        sort: "seq, id",
        populate: ['location', "works"],
    }));

    const { data: selectEquipmentData } = useQuery(equipments?.find({
        query: {
            $and: [
                {
                    influxEquipmentCode: {
                        $and: [
                            { $not: null },
                            { $not: '' },
                            { $or: userActionConditions }
                        ],
                    },
                },
            ],
        },
        sort: "seq, id",
        populate: ['location', "works"],
    }));

    const [monitoringData, setMonitoringData] = useState<Array<any>>([]);
    const equipmentCode = selectEquipmentData?.data &&
        selectEquipmentData?.data?.map((data: AuthSignupPost201ResponseEquipment) => data?.code);

    const { socket, error } = useSocket(BASE_URL, {
        autoConnect: true,
    });
    // console.log(equipmentCode)
    useEffect(() => {
        // monitoringData 내의 각 항목의 option 속성이 selectedOptions 배열에 포함되어 있는지 확인
        const filteredData = monitoringData.filter(data => data?.option && selectedOptions.includes(data?.option));
        setMonitoringData(filteredData);

        equipmentCode?.forEach((code, index) => {
            const roomName = `PM_${code}`;
            socket.on(roomName, (message: any) => {
                setMonitoringData((prevData: any) => {
                    const newData = [...prevData];
                    newData[index] = message;
                    return newData;
                });
            });
            socket.emit(
                "call",
                "socket.join",
                { room: roomName },
                function (err: any, res: any) {
                    if (err) {
                        console.error(err);
                    }
                }
            );
        });
        // 컴포넌트가 언마운트될 때 소켓의 이벤트 리스너를 제거
        return () => {
            equipmentCode?.forEach((code) => {
                const roomName = `PM_${code}`;
                socket.off(roomName);
            });
        };
    }, [socket, selectEquipmentData, selectedOptions]); // selectedOptions를 의존성 배열에 추가

    // equipmentData를 MultiSelect 컴포넌트에서 사용할 수 있는 형태로 변환
    const multiSelectData = equipmentData?.data?.reduce((acc: { value: string; label: string; }[], equipment: AuthSignupPost201ResponseEquipment) => {
        if (equipment?.influxEquipmentCode) { // influxEquipmentCode가 undefined가 아닌 경우에만 배열에 추가
            acc.push({
                value: equipment?.influxEquipmentCode,
                label: equipment?.name
            });
        }
        return acc;
    }, []);

    return (
        <>
            <MultiSelect
                data={multiSelectData || []}
                placeholder="검색할 기계를 선택해주세요."
                value={selectedOptions}
                onChange={setSelectedOptions}
            />
            <Monitoring.Parents>
                {monitoringData?.map((data: any, index: number) => {
                    return (
                        <MonitoringBox data={data} index={index} />
                    )
                })}

            </Monitoring.Parents>
        </>
    )
}