import { items } from "@/api/items/useItemQuery";
import useGetItemRoutingsQuery from "@/api/useGetRoutingQuery";
import useWorkSitesQuery from "@/api/useGetSitesQuery";
import { WorkOrderTable } from "@/features/ui/table/WorkOrderTable";
import { useModal } from "@/hooks";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { CloseButton, Flex, Select, Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useRef, useState } from "react";
import { useQuery } from "react-query";

export interface WorkOrderInput {
  workplace: string;
  item: ItemsGet200ResponseRowsInner | null;
}

export const WorkOrderModal = () => {
  const inputRef = useRef<{
    date: HTMLInputElement | null;
    site: HTMLInputElement | null;
    product?: HTMLInputElement | null;
    quantity?: HTMLInputElement | null;
  }>({
    date: null,
    site: null,
    product: null,
    quantity: null,
  });

  const [item, setItem] = useState<ItemsGet200ResponseRowsInner>();
  //품목선택
  const { data: itemData } = useQuery(items.get());
  //사업장
  const { data: sites } = useWorkSitesQuery({ firstRequest: true });
  //품목에 대한 라우팅
  const { data: routings } = useGetItemRoutingsQuery({
    itemCode: item?.code,
    quantity: "0",
  });

  const { modal, closeModal } = useModal();

  const [inputs, setInputs] = useState({
    product: "",
    site: "",
  });

  const itemList = itemData?.data?.rows;
  const sitesList = sites?.data?.rows;
  const routing = routings?.data;

  return (
    <>
      {modal?.modalType === "WorkOrder" && modal.isOpen && (
        <ModalContainer>
          <Flex align="center">
            <Text fz="lg" mb={12} mr="auto">
              작업 지시 생성
            </Text>
            <CloseButton
              size="xl"
              iconSize={32}
              aria-label="Close modal"
              onClick={closeModal}
            />
          </Flex>
          <Text fz="sm" mb="sm">
            라우팅별 작업 지시를 생성합니다.
          </Text>
          <InputContainer>
            <LeftSelect
              ref={(el: HTMLInputElement | null) =>
                (inputRef.current["product"] = el)
              }
              value={item?.name}
              label="품목 선택"
              description="생산할 품목을 선택합니다."
              placeholder="Pick value"
              data={itemList?.map((item) => item.name) ?? []}
              size="md"
              allowDeselect
              mr={12}
              onChange={(value: string) => {
                const selectedItem =
                  value && itemList?.filter((item) => item.name === value);
                if (selectedItem) {
                  setItem(selectedItem[0]);
                }
              }}
            />
            <RightSelect
              label="사업장"
              description="생산할 사업장을 선택합니다."
              defaultValue={sitesList && sitesList[0]?.name}
              value={inputs?.site}
              data={sitesList?.map((site) => site.name) ?? []}
              size="md"
              onChange={(value: string) => {
                setInputs({
                  ...inputs,
                  site: value,
                });
              }}
            />
          </InputContainer>
          <WarningRouting routings={routings} />
          <WorkOrderTable data={{ item: item, routing: routing }} />
        </ModalContainer>
      )}
    </>
  );
};

const WarningRouting = ({ routings }: any) =>
  routings?.data.length < 1 ? (
    <WarningText>
      {
        "품목을 생산하기 위한 라우팅이 설정되어 있지 않아 생산을 계획할 수 없습니다."
      }
    </WarningText>
  ) : (
    <></>
  );

const WarningText = styled<any>(Text)`
  color: var(--red-6, #fa5252);
  /* components/input/error/xs */
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
`;
const ModalContainer = styled.div`
  position: fixed;
  width: 1020px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: ${theme.colors?.white?.[1]};
  border: 1px solid #ccc;
  z-index: 100;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 12px 0;
`;

const LeftSelect = styled<any>(Select)`
  margin-right: auto;
`;

const RightSelect = styled<any>(Select)`
  margin-left: auto;
`;
