import { MaterialAutoComplete } from "@/features/ui/autoComplete/material/Material-autoComplete";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Button, TextInput } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";

export const RoutingBomFormTableRow = ({
  routingRowIndex,
  formIndex,
  formData,
}: any) => {
  const handleClickDelete = (index: number) => {
    formData.removeListItem(
      `routingRows.${routingRowIndex}.routingBoms`,
      index
    );
  };

  const setUnitText = (unitText: string) => {
    // routingBom 객체를 가져옵니다.
    let routingBom = formData.values.routingRows[routingRowIndex].routingBoms[formIndex];

    // routingBom 객체가 없거나, routingBom.item 객체가 없는 경우 새로운 객체를 생성합니다.
    if (!routingBom) {
      routingBom = { item: {} };
    } else if (!routingBom.item) {
      routingBom.item = {};
    }

    // unit_text 속성을 설정합니다.
    routingBom.item.unit_text = unitText;

    // routingBom 객체를 업데이트합니다.
    formData.setFieldValue(`routingRows.${routingRowIndex}.routingBoms.${formIndex}`, routingBom);
  };

  return (
    <>
      <Tr key={formIndex}>
        <Td width={15}>
          <TextInput
            type="number"
            {...formData.getInputProps(
              `routingRows.${routingRowIndex}.routingBoms.${formIndex}.seq`
            )}
          />
        </Td>
        <Td width={50}>
          <MaterialAutoComplete
            {...formData.getInputProps(
              `routingRows.${routingRowIndex}.routingBoms.${formIndex}.itemCode`
            )}
            setUnitText={setUnitText}
          />
        </Td>
        <Td width={20}>
          <TextInput
            type="number"
            {...formData.getInputProps(
              `routingRows.${routingRowIndex}.routingBoms.${formIndex}.quantity`
            )}
          />
        </Td>
        <Td width={20}>
          <DeleteButton
            disabled={
              !(
                formData.values.routingRows[routingRowIndex].routingBoms
                  .length > 1
              )
            }
            onClick={() => handleClickDelete(formIndex)}
            leftIcon={<IconTrash />}
          >
            삭제
          </DeleteButton>
        </Td>
      </Tr>
    </>
  );
};

const Tr = styled.tr`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`;

const Td = styled.td<{ width: number }>`
  display: flex;
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
  width: ${(props) => props.width}%;
`;

const DeleteButton = styled<any>(Button)`
  color: black;
  background-color: ${theme.colors?.white};
  font-size: 14px;

  &:hover {
    background-color: ${theme.colors?.blue?.[0]};
  }
`;
