import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Badge, Box, Button, Chip, Flex, Pagination, useMantineColorScheme } from "@mantine/core";
import { useEffect, useReducer, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { FormatterProps, SelectColumn } from "react-data-grid";

import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { workLogHeader } from "@/constants/columnHeader";
import { EquipmentDetailForm } from "@/features/equipment/components/form/EquipmentDetailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { ChipsBox } from "@/features/standard/Chips/ChipsBox";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { WorkView } from "@/features/work/WorkView/WorkView";
import { theme } from "@/styles/theme";
import { setToLocaleString } from "@/utils/unitMark";
import { setWorkStatus, statusObj, trackingStatusColor } from "@/utils/workStatus";
import { WorkLogsGet200ResponseRowsInner, WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models/src/model";
import dayjs from "dayjs";
import { useQuery } from "react-query";


interface ColorThemeProps {
    isDarkMode: boolean;
}

type State = {
    [key: string]: boolean;
};

type Action = {
    type: 'toggle';
    name: string;
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'toggle':
            return { ...state, [action.name]: !state[action.name] };
        default:
            throw new Error();
    }
}


export const WorkLogTable = () => {

    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const isDarkMode: boolean = colorScheme === "light";
    const [state, dispatch] = useReducer(reducer, { "INPUT": true, "PRODUCTION": true });

    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const [formatterProps, setFormatterProps] = useState<
        FormatterProps<WorkLogsGet200ResponseRowsInner, unknown> | undefined
    >();
    const [selectedRows, setSelectedRows] = useState(
        (): ReadonlySet<any> => new Set()
    );
    const [activePage, setPage] = useState(1);

    const [query, setQuery] = useState({
        $and: [
            {
                workLogType: Object.keys(state).map((key) => WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum[key as keyof typeof WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum])
            },
        ]
    });
    const [sort, setSort] = useState([]);

    const [search, setSearch] = useState<SearchProps>({
        search: "",
        searchFields: [],
        pageSize: "10"
    });

    const MAX_DISPLAY = 10;
    const [isExpanded, setIsExpanded] = useState(false);

    const { openModal } = useModal();

    const isSearch = query.$and.length || search.search || search.searchFields;
    const isSort = sort.length;

    // isSearch가 변경될 때마다 실행되는 useEffect를 추가합니다.
    useEffect(() => {
        if (isSearch) {
            setPage(1);
        }
    }, [isSearch]);

    useEffect(() => {
        setQuery({
            $and: [
                ...query.$and.map((item) => {
                    if (item.workLogType) {
                        return {
                            ...item,
                            workLogType: Object.keys(state).reduce((result: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum[], key: string) => {
                                if (state[key as keyof typeof state]) {
                                    result.push(WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum[key as keyof typeof WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum]);
                                }
                                return result;
                            }, [])
                        }
                    }
                    return item;
                })
            ]
        });
    }, [dispatch, state]);


    const searchFieldsHeader = workLogHeader.filter(
        (workLogs) => workLogs.category === "text" || workLogs.category === "number"
    );

    const { data: workLogsData, refetch } = useQuery(workLogs.get({
        query: query,
        search: search.search,
        searchFields: search.searchFields.length
            ? search.searchFields
            : searchFieldsHeader.map((workLogs) => workLogs.value),
        page: activePage,
        pageSize: Number(search.pageSize),
        sort: sort.length ? sort.join(",") : "-createdAt",
        populate: ["itemUnit", "defectName", "alreadyCanceled", "creatorUser", "downtimeReasonName", "getEquipment", "currentLotSummary"],
    }));

    useEffect(() => {
        refetch();
    }, [isSort, refetch]);

    const rows: readonly WorkLogsGet200ResponseRowsInner[] =
        workLogsData?.data.rows ?? [];
    const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

    const columns: readonly Column<WorkLogsGet200ResponseRowsInner | any>[] = [
        {
            ...SelectColumn,
            width: 70,
            maxWidth: 500,
            resizable: true,
            headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
            cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
        },
        {
            key: "id",
            name: "로그번호",
            sortable: true,
            resizable: true,
            width: 20,
            cellClass: css`
                justify-content: flex-end;
            `,
        },
        {
            key: "createdAt",
            name: "일시",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <div>
                        {row.createdAt !== null ? dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss") : '-'}
                    </div>
                )
            }
        },
        {
            key: "equipment.name",
            name: "설비코드(설비명)",
            width: 150,
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <EquipmentDetailForm equipmentCode={row.equipmentCode} />,
                                null,
                                "설비 상세"
                            )
                        }}>
                        {row.equipmentCode}({row?.equipment?.name})
                    </DetailLink>
                );
            }
        },
        {
            key: "workLogType",
            name: "타입",
            width: 110,
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                const badgeColor = trackingStatusColor[row.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum] || "defaultColor";
                return (
                    <Box>
                        <Badge size="lg" color={badgeColor}>
                            {setWorkStatus(row.workLogType)}
                            {row.workLogType === "TIME_TRACKING_PAUSE" && `(${row.downtimeReasonName})`}
                            {row.workLogType === "DEFECT" && `(${row.defectName})`}
                        </Badge>
                    </Box>
                )
            }
        },
        {
            key: "quantity",
            name: "수량",
            sortable: true,
            resizable: true,
            cellClass: css`
                justify-content: flex-end;
            `,
            formatter: ({ row }) => {
                return (
                    <div>
                        {row.quantity !== null ? setToLocaleString(row.quantity) : "0"} {row.unitText}
                    </div>
                );
            }
        },
        {
            key: "totalQuantity",
            name: "양품수량",
            sortable: true,
            resizable: true,
            cellClass: css`
                justify-content: flex-end;
            `,
            formatter: ({ row }) => {
                return (
                    <div>
                        {["PRODUCTION", "LOSS", "CANCEL_PRODUCTION", "CANCEL_LOSS"].includes(row?.workLogType)
                            ? `${setToLocaleString(row?.currentLotSummary?.totalQuantity)} ${row.unitText}`
                            : ""}
                    </div>
                );
            }
        },
        {
            key: "itemCode",
            name: "품목코드",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <DetailLink onClick={() => {
                        openModal(
                            <ItemsDetailForm itemCode={row.logData?.incoming ? row.logData?.incoming.itemCode : row.logData?.outgoing?.itemCode} />,
                            null,
                            "품목 상세"
                        )
                    }}>
                        {row.logData?.incoming ? row.logData?.incoming.itemCode : row.logData?.outgoing?.itemCode}
                    </DetailLink>
                );

            }
        },
        {
            key: "lotData.name",
            name: "로트명",
            sortable: true,
            resizable: true,
            width: 180,
            formatter: ({ row }) => {
                return (
                    <>
                        {row.logData?.incoming ?
                            <div>
                                <span>{row.logData?.incoming.lotData?.name} </span>
                            </div> :
                            <div>
                                <span>{row.logData?.outgoing?.lotData?.name} </span>
                            </div>
                        }
                    </>
                );
            }
        },
        {
            key: "lotData.expiration",
            name: "로트 유효기한",
            sortable: true,
            resizable: true,
            width: 180,
            formatter: ({ row }) => {
                return (
                    <>
                        {row.logData?.incoming ?
                            <div>
                                <span>
                                    {
                                        row.logData?.incoming?.lotData?.expiration ?
                                            dayjs(row.logData?.incoming?.lotData.expiration).format("YYYY-MM-DD HH:mm:ss") : ""
                                    }
                                </span>
                            </div> :
                            <div>
                                <span>
                                    {
                                        row.logData?.outgoing?.lotData?.expiration ?
                                            dayjs(row.logData?.outgoing?.lotData.expiration).format("YYYY-MM-DD HH:mm:ss") : ""
                                    }
                                </span>
                            </div>
                        }
                    </>
                );
            }
        },
        {
            key: "locationData.name",
            name: "투입 로케이션",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <LocationDetailForm locationCode={row?.logData?.incoming?.locationCode || row?.logData?.outgoing?.locationCode} />,
                                null,
                                "로케이션 상세"
                            )
                        }}>
                        {row.logData?.incoming ? row.logData?.incoming.locationData?.name : row.logData?.outgoing?.locationData?.name}
                    </DetailLink>
                );
            },
        },
        {
            key: "workId",
            name: "작업번호",
            width: 30,
            sortable: true,
            resizable: true,
            cellClass: css`
                justify-content: flex-end;
            `,
            formatter: ({ row }) => {
                return (
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <WorkView workId={row.workId} />,
                                null,
                                "작업지시"
                            );
                        }}
                    >
                        {row.workId}
                    </DetailLink>
                );
            }
        },
        {
            key: "productionPlanId",
            name: "생산계획번호",
            width: 30,
            sortable: true,
            resizable: true,
            cellClass: css`
                justify-content: flex-end;
            `,
            formatter: ({ row }) => {
                return (
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <ProductionPlanViewForm ProductionPlanId={row.productionPlanId} />,
                                null,
                                "생산계획"
                            );
                        }}
                    >
                        {row.productionPlanId}
                    </DetailLink>
                );
            }
        },
        {
            key: "creatorUserCode",
            name: "작업자",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <DetailLink onClick={() => openModal(<UsersDetailForm UserCode={row?.creatorUserCode} />, null, "")}>
                        {row?.creatorUserCode}
                    </DetailLink>
                );

            }
        }
    ];

    return (
        <WorkLogsTableWrapper>
            <GridWrapper isDarkMode={isDarkMode}>
                <TableWrapper>
                    <RetrieveWrapper>
                        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
                            <CustomFilter filterType={workLogHeader} setQuery={setQuery} />
                            <CustomSorter sorterType={workLogHeader} setSort={setSort} />
                        </Flex>
                        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} />
                    </RetrieveWrapper>
                    <RetrieveWrapper>
                        <ChipsBox>
                            {
                                Object.keys(statusObj).map((key, index) => (
                                    (isExpanded || index < MAX_DISPLAY) && (
                                        <Chip variant="filled" size="sm" radius="xs"
                                            color={trackingStatusColor[key]}
                                            key={key} checked={state[key] || false}
                                            onClick={() => dispatch({ type: 'toggle', name: key })}>{statusObj[key]}
                                        </Chip>
                                    )
                                ))
                            }
                            {Object.keys(statusObj).length > MAX_DISPLAY && (
                                <Button
                                    onClick={() => setIsExpanded(!isExpanded)}
                                    radius="xs"
                                    size="xs"
                                    fz="sm"
                                    variant="filled"
                                    color={isExpanded ? "gray" : "blue"}
                                >
                                    {isExpanded ? '숨기기' : '더보기'}
                                </Button>
                            )}
                        </ChipsBox>
                    </RetrieveWrapper>
                    <DataGrid
                        columns={columns ?? []}
                        rows={rows as any}
                        rowHeight={40}
                        rowKeyGetter={(row) => row.id}
                        sortColumns={sortColumns}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={(e) => {
                            setSelectedRows(e);
                            const newSelectedRows = new Set(selectedRowsRef.current);
                            newSelectedRows.add(e);
                            selectedRowsRef.current = newSelectedRows;
                        }}
                    />
                </TableWrapper>
                <PaginationWrapper>
                    <Pagination
                        onChange={setPage}
                        value={activePage}
                        total={workLogsData?.data?.totalPages ?? 0}
                        size="lg"
                        radius="sm"
                    />
                </PaginationWrapper>
            </GridWrapper>
        </WorkLogsTableWrapper>
    );
};

const WorkLogsTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
        props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
        props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;
const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
