import { RoutingBomFormTableRow } from "@/features/item/components/form/itemsStepperForm/routingsBomForm/RoutingBomFormTableRow";
import styled from "@emotion/styled";
import {
  Button,
  Flex,
  Table,
  Title,
  useMantineColorScheme,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";

export const RoutingBomForm = (params: {
  formData: any; //UseFormReturnType<Partial<RoutingFormProps>>;
  checkValue: string | null;
  formIndex: number;
}) => {
  const {
    formData: form,
    checkValue: id,
    formIndex: routingRowIndex,
  } = params;

  const row = form.values.routingRows[routingRowIndex];
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";
  const handleClickAdd = () => {
    form.insertListItem(`routingRows.${routingRowIndex}.routingBoms`, {
      routingCode: undefined, // 라우팅코드
      itemCode: undefined,
      quantity: undefined,
      seq: undefined,
    });
  };
  return (
    <>
      <RoutingBomFormWrapper>
        <Flex direction="column" gap="xs">
          <Title order={2}>라우팅 BOM 입력</Title>
          <Flex direction="column">
            <SubTitle>
              선택된 라우팅이 진행될 때 소모될 원/부자재 및 반제품 수량을 입력합니다.
            </SubTitle>
            <SubTitle>
              소모할 원/부자재 및 반제품이 없다면 입력하지 않아도 됩니다.
            </SubTitle>
          </Flex>
        </Flex>
        <Table style={{ width: "100%" }}>
          <Thead>
            <Tr>
              <Th width={15}>시퀀스</Th>
              <Th width={45}>원부자재</Th>
              <Th width={15}>수량</Th>
              <Th width={15}>&nbsp;</Th>
            </Tr>
          </Thead>
          <Tbody>
            {row.routingBoms &&
              row.routingBoms.map((_: any, index: number) => (
                <>
                  <RoutingBomFormTableRow
                    key={index + " " + routingRowIndex}
                    routingRowIndex={routingRowIndex}
                    formIndex={index}
                    formData={form}
                  />
                  {row.routingBoms.length - 1 === index &&
                    row.routingBoms.length <= 10 && (
                      <AddButtonTr>
                        <AddButton
                          onClick={handleClickAdd}
                          leftIcon={<IconPlus />}
                          variant={isDarkMode ? "white" : "dark"}
                          color={isDarkMode ? "dark" : "white"}
                        >
                          새로운 라우팅 추가
                        </AddButton>
                      </AddButtonTr>
                    )}
                </>
              ))}
          </Tbody>
        </Table>
      </RoutingBomFormWrapper>
    </>
  );
};

const RoutingBomFormWrapper = styled.div`
  width: 40%;
`;

// const Title = styled.span`
//   font-size: 20px;
//   font-weight: 500;
//   line-height: 32px;
// `;

const SubTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

const Thead = styled.thead`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Tr = styled.tr`
  width: 100%;
  display: flex;
  height: 40px;
`;

const Th = styled.th<{ width: number }>`
  width: ${(props) => props.width}%;
`;

const Tbody = styled.tbody`
  width: 100%;
`;

const AddButtonTr = styled.tr`
  width: 100%;
  display: flex;
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
`;

const AddButton = styled<any>(Button)`
  display: flex;
  width: 100%;
  padding: 1px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
`;
