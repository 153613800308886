import { createContext, useMemo, useState } from "react";

interface WorkContextProps {
  site?: string;
  line?: string;
  date: Date | string;
  defectCodes: string[] | null;
  workDate: Date[] | string[];
  equipmentCode?: string;
  itemCode?: string;
}

export const WorkByProductionStateContext = createContext({
  site: "",
  line: "",
  date: new Date(),
  defectCodes: null,
  workDate: [new Date()],
  equipmentCode : "",
  itemCode : "",
});
export const WorkByProductionActionsContext = createContext({});

export const WorkByProductionProvider = ({ children }: any) => {
  const [workState, setWorkState]: any = useState<WorkContextProps>({
    site: "",
    line: "",
    date: new Date(),
    defectCodes: null,
    workDate: [new Date()],
    equipmentCode : "",
    itemCode : "",
  });

  const actions: any = useMemo(
    () => ({
      setDate: (date: Date) => {
        setWorkState((prev: any) => ({
          ...prev,
          date,
        }));
      },
      setSite: (site: string) => {
        setWorkState((prev: any) => ({
          ...prev,
          site,
        }));
      },
      setLine: (line: string) => {
        setWorkState((prev: any) => ({
          ...prev,
          line,
        }));
      },
      setDefectReason: (defectCodes: string[]) => {
        setWorkState((prev: any) => ({
          ...prev,
          defectCodes: defectCodes,
        }));
      },
      setWorkDate: (workDate: Date[]) => {
        setWorkState((prev: any) => ({
          ...prev,
          workDate,
        }));
      },
      setEquipment: (equipmentCode: string) => {
        setWorkState((prev: any) => ({
          ...prev,
          equipmentCode,
        }));
      },
      setItem: (itemCode: string) => {
        setWorkState((prev: any) => ({
          ...prev,
          itemCode,
        }));
      },
    }),
    []
  );

  return (
    <WorkByProductionActionsContext.Provider value={actions}>
      <WorkByProductionStateContext.Provider value={workState}>
        {children}
      </WorkByProductionStateContext.Provider>
    </WorkByProductionActionsContext.Provider>
  );
};
