import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs } from "@mantine/core";

import { specifications } from "@/api/inspect/inspectSpecification/useSpecificationsQuery";
import { useForm } from "@mantine/form";
import { useQuery } from "react-query";
import { InspectCriteriaCreateInform } from "../../inspectCriteria/inform/InspectCriteriaICreateInform";
import { InspectCriterialTable } from "../../inspectCriteria/table/InspectCriterialTable";
import { InspectSpecificationInformWithItem } from "../inform/InspectSpecificationInformWithItem";

export const InspectSpecificationCreateFormWithItems = () => {
  const form = useForm<Partial<any>>({
    initialValues: {
      itemCode: undefined,
    },
  });


  const { data: specification } = useQuery({
    ...specifications.get({
      query: {
        $and: [
          {
            itemCode: form.values.itemCode,
          }
        ]
      }
    }),
    enabled: !!form.values.itemCode
  });

  return (
    <>
      <RoutingWrapper>
        <RoutingDetailFormContainer>
          <InspectSpecificationInformWithItem formData={form} />
          <Tabs
            variant="outline"
            defaultValue="inspectCriteria"
            styles={(theme) => ({
              tab: {
                "&:not([data-active])": {
                  backgroundColor: theme.colors?.gray?.[0],
                },
              },
            })}
          >
            <Tabs.List>
              <LeftSpace />
              <Tabs.Tab value="inspectCriteria">검사기준</Tabs.Tab>
              <RightSpace />
            </Tabs.List>
            <Tabs.Panel value="inspectCriteria">
              <InspectCriteriaCreateInform
                data={{
                  itemCode: form.values.itemCode,
                  criteria: specification?.data?.rows?.[0]?.criteria,
                  specificationId: specification?.data?.rows?.[0]?.id,
                }}
              />
              <InspectCriterialTable data={specification?.data.rows} />
            </Tabs.Panel>
          </Tabs>
        </RoutingDetailFormContainer>
      </RoutingWrapper>
    </>
  );
};

const InspectStandardInformWrapper = styled.div`
  padding: 16px 32px;
`;

const RoutingDetailFormContainer = styled.div`
  width: 75rem;
  padding-top: 60px;
  background: #ffffff;
  border: 1px solid #ebeef1;
`;

const RoutingWrapper = styled.div`
  padding: 10px 10px;
  background-color: #f8f9fa;
`;

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
