import {
    MantineReactTable,
    useMantineReactTable,
    // createRow,
    type MRT_ColumnDef
} from 'mantine-react-table';
import { useMemo } from 'react';

import { workLogs } from '@/api/workLogs/useWorksLogsQuery';
import { ItemsDetailForm } from '@/features/item/components/form/ItemsDetailForm';
import { LocationDetailForm } from '@/features/location/detail/LocationDetailForm';
import { useModal } from '@/features/modal/ModalStackManager';
import { DetailLink } from '@/features/ui/detail/DetailLink';
import { UsersDetailForm } from '@/features/users/components/form/UsersDetailForm';
import { WorkView } from '@/features/work/WorkView/WorkView';
import DatetimeUtil from '@/utils/dateTimeUtil';
import { setWorkStatus, trackingStatusColor } from '@/utils/workStatus';
import { Badge, DefaultMantineColor } from '@mantine/core';
import { WorkLogsGet200ResponseRowsInner } from '@sizlcorp/sizl-api-document/dist/models';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ProductionPlanFormProps } from '../../view';

// export interface WorkLogsGet200ResponseRowsInner {
//     creatorUserCode: string | any;
//     createdAt: string | any;
//     quantity: number | any;
//     workLogType: string | any;
//     id: number | any;
//     workId: number | any;
//     workLogId: number | any;
//     unitText: string | any;
//     logData: {
//         incoming?: {
//             locationCode: string | any;
//             createdAt: string | any;
//             itemCode: string | any;
//             lotData: {
//                 name: string | any;
//                 expiration: string | any;
//             }
//         }
//         outgoing?: {
//             locationCode: string | any;
//             createdAt: string | any;
//             itemCode: string | any;
//             lotData: {
//                 name: string | any;
//                 expiration: string | any;
//             }
//         }

//     }
// }

export const WorkRecordsTabs = ({ data }: ProductionPlanFormProps) => {

    const { openModal } = useModal();
    const workId = data?.works && data?.works[0]?.id;

    const { data: workLogsData } = useQuery(workLogs.get({
        query: data ? { $and: [{ workId: { $eq: workId } }] } : '',
        populate: ["itemUnit", "defectName", "alreadyCanceled", "creatorUser", "downtimeReasonName", "getEquipment"],
    }))

    const rows: WorkLogsGet200ResponseRowsInner[] =
        workLogsData?.data?.rows ?? [];

    const { t } = useTranslation();

    const columns = useMemo<MRT_ColumnDef<WorkLogsGet200ResponseRowsInner>[]>(
        () => [
            {
                accessorFn: (row) => row.id,
                accessorKey: 'logId',
                header: '로그번호',
                size: 80,
            },
            {
                accessorFn: (row) => row.workId,
                accessorKey: 'workId',
                header: '작업지시',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    return (
                        <DetailLink
                            onClick={() => {
                                openModal(
                                    <WorkView workId={rows.row._valuesCache.workId} />,
                                    null,
                                    ''
                                )
                            }}
                            justify="flex-start"
                        >
                            {rows.row._valuesCache.workId}
                        </DetailLink>
                    )
                },
            },
            {
                accessorFn: (row) => row.logData?.incoming && row.logData?.incoming.createdAt ?
                    DatetimeUtil(row.logData?.incoming.createdAt) :
                    row.logData?.outgoing && row.logData?.outgoing.createdAt ?
                        DatetimeUtil(row.logData?.outgoing?.createdAt) :
                        '',
                accessorKey: 'createdAt',
                header: '일시',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
            },
            {
                // accessorFn: (row) => t(row.workLogType),
                accessorKey: 'workLogType',
                header: '타입',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    const workLogType = rows.row._valuesCache.workLogType as keyof typeof trackingStatusColor;

                    return (
                        <Badge color={trackingStatusColor[workLogType] as DefaultMantineColor}>
                            {setWorkStatus(rows.row._valuesCache.workLogType)}
                        </Badge>
                    )
                }
            },
            {
                accessorFn: (row) => row.quantity,
                accessorKey: 'quantity',
                header: '수량',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    const unitText = rows.row.original.unitText;
                    return (
                        <div style={{ textAlign: 'right' }}>
                            {rows.row._valuesCache.quantity !== null ? rows.row._valuesCache.quantity : "0"} {unitText}
                        </div>
                    )
                }
            },
            {
                accessorFn: (row) => row.logData?.incoming ?
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <ItemsDetailForm itemCode={row?.logData?.incoming?.itemCode} />,
                                null,
                                ''
                            )
                        }}
                        justify="flex-start"
                    >
                        {row.logData?.incoming.itemCode}
                    </DetailLink>
                    :
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <ItemsDetailForm itemCode={row?.logData?.outgoing?.itemCode} />,
                                null,
                                ''
                            )
                        }}
                        justify="flex-start"
                    >
                        {row?.logData?.outgoing?.itemCode}
                    </DetailLink>,
                accessorKey: 'itemCode',
                header: 'BOM 품목',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },

            },
            {
                accessorFn: (row) => row.logData?.incoming ?
                    [<div>이름 : {row.logData?.incoming.lotData?.name}</div>,
                    <div>유효기한 : {row.logData?.incoming?.lotData?.expiration}</div>] :
                    [<div>이름 : {row.logData?.outgoing?.lotData?.name}</div>,
                    <div>유효기한 : {row.logData?.outgoing?.lotData?.expiration}</div>],
                accessorKey: 'lotData',
                header: 'BOM 로트',
                size: 160,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
            },
            {
                accessorFn: (row) => row.logData?.incoming ?
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <LocationDetailForm locationCode={row?.logData?.incoming?.locationCode} />,
                                null,
                                '로케이션 상세'
                            )
                        }}
                        justify="flex-start"
                    >{row.logData?.incoming.locationCode}
                    </DetailLink>
                    :
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <LocationDetailForm locationCode={row?.logData?.outgoing?.locationCode} />,
                                null,
                                '로케이션 상세'
                            )
                        }}
                        justify="flex-start"
                    >{row.logData?.outgoing?.locationCode}
                    </DetailLink>,
                accessorKey: 'locationCode',
                header: '투입 로케이션',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
            },
            {
                accessorFn: (row) => row.parentWorkLogId,
                accessorKey: 'parentWorkLogId',
                header: '연관 로그번호',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
            },
            {
                accessorFn: (row) => row.creatorUserCode,
                accessorKey: 'creatorUserCode',
                header: '작업자',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    return (
                        <DetailLink onClick={() => openModal(<UsersDetailForm UserCode={rows.row._valuesCache.creatorUserCode} />, null, "")} justify="flex-start">
                            {rows.row._valuesCache.creatorUserCode}
                        </DetailLink>
                    )
                },
            },
        ],
        [],
    );

    const table = useMantineReactTable({
        columns,
        data: rows,
        editDisplayMode: 'modal',
        enableEditing: false,
        enableToolbarInternalActions: false, // 우측상단 아이콘 안보이게
        enablePagination: false,
    });

    return <MantineReactTable table={table} />;
};


