export interface ColumnHeaderProps {
  label: string;
  value: string;
  category: string;
  isFK: boolean;
  isEnum?: boolean;
}

export const itemsHeader: ColumnHeaderProps[] = [
  { label: "품목명", value: "name", category: "text", isFK: false },
  { label: "품목코드", value: "code", category: "text", isFK: false },
  { label: "품목유형", value: "itemType", category: "text", isFK: false, isEnum: true },
  { label: "품목분류", value: "itemCategoryName", category: "text", isFK: true },
  { label: "품목군", value: "abstractItemCode", category: "text", isFK: true },
  { label: "규격", value: "spec", category: "text", isFK: false },
  { label: "높이", value: "height", category: "number", isFK: false },
  { label: "깊이", value: "depth", category: "number", isFK: false },
  { label: "폭", value: "width", category: "number", isFK: false },
  { label: "재질", value: "texture", category: "text", isFK: false },
  { label: "단위", value: "unitText", category: "text", isFK: false },
];
export const equipmentsHeader: ColumnHeaderProps[] = [
  { label: "사업장코드", value: "siteCode", category: "text", isFK: true },
  { label: "설비코드", value: "code", category: "text", isFK: false },
  { label: "설비명", value: "name", category: "text", isFK: false },
  { label: "설비유형", value: "equipmentType", category: "text", isFK: false },
];
export const defectsHeader: ColumnHeaderProps[] = [
  { label: "불량코드", value: "code", category: "text", isFK: false },
  { label: "불량명", value: "name", category: "text", isFK: false },
  { label: "공정코드", value: "operationCode", category: "text", isFK: true },
];
export const operationsHeader: ColumnHeaderProps[] = [
  { label: "공정코드", value: "code", category: "text", isFK: false },
  { label: "사업장코드", value: "siteCode", category: "text", isFK: true },
  { label: "공정명", value: "name", category: "text", isFK: false },
];
export const routingsHeader: ColumnHeaderProps[] = [
  // { label: "품목명", value: "itemName", category: "text" },
  { label: "품목코드", value: "itemCode", category: "text", isFK: true },
  // { label: "규격", value: "spec", category: "text" },
  { label: "공정코드", value: "operationCode", category: "text", isFK: true },
  { label: "라우팅코드", value: "code", category: "text", isFK: false },
  { label: "라우팅유형", value: "routingType", category: "text", isFK: false, isEnum: true },
  { label: "금형코드", value: "moldCode", category: "text", isFK: true },
];
export const usersHeader: ColumnHeaderProps[] = [
  { label: "사용자코드", value: "code", category: "text", isFK: false },
  { label: "사용자명", value: "name", category: "text", isFK: false },
  { label: "로그인아이디", value: "username", category: "text", isFK: false },
  { label: "설비코드", value: "equipmentCode", category: "text", isFK: true },
  { label: "프린터 코드", value: "printerCode", category: "text", isFK: false },
];
export const sitesHeader: ColumnHeaderProps[] = [
  { label: "사업장코드", value: "code", category: "text", isFK: false },
  { label: "사업장명", value: "name", category: "text", isFK: false },
];
export const locationsHeader: ColumnHeaderProps[] = [
  { label: "로케이션코드", value: "code", category: "text", isFK: false },
  { label: "로케이션이름", value: "name", category: "text", isFK: false },
  { label: "사업장코드", value: "siteCode", category: "text", isFK: true },
];
export const routingOutsourcesHeader: ColumnHeaderProps[] = [
  { label: "외주처명", value: "name", category: "text", isFK: false },
  { label: "라우팅코드", value: "routingCode", category: "text", isFK: true },
  { label: "자재 출고 로케이션코드", value: "fromLocationCode", category: "text", isFK: true },
  { label: "재공품 입고 로케이션코드", value: "toLocationCode", category: "text", isFK: true },
];

export const inventoriesHeader: ColumnHeaderProps[] = [
  { label: "품목코드", value: "itemCode", category: "text", isFK: true },
  { label: "로트명", value: "lotName", category: "text", isFK: false },
  // { label: "로트 유효기한", value: "expiration", category: "date" },
  { label: "위치", value: "locationCode", category: "text", isFK: true },
  { label: "추가된 재고", value: "increaseQuantity", category: "number", isFK: false },
  { label: "감소된 재고", value: "decreaseQuantity", category: "number", isFK: false },
  { label: "재고 변화량", value: "deltaQuantity", category: "number", isFK: false },
  { label: "최종 재고", value: "quantity", category: "number", isFK: false },
  { label: "마감일시", value: "closedAt", category: "date", isFK: false },
  { label: "마감 재고", value: "closedQuantity", category: "number", isFK: false },
];

export const wipInventoriesHeader: ColumnHeaderProps[] = [
  { label: "품목코드", value: "itemCode", category: "text", isFK: true },
  { label: "위치", value: "locationCode", category: "text", isFK: true },
  // { label: "재고 라우팅단계", value: "routingStep", category: "text" },
  { label: "수량", value: "quantity", category: "number", isFK: false },
];
export const LedgersHeader: ColumnHeaderProps[] = [
  { label: "품목코드", value: "itemCode", category: "text", isFK: true },
  { label: "위치", value: "locationCode", category: "text", isFK: true },
  // { label: "사업장", value: "locationData.site_code", category: "text", isFK: true },
  { label: "사용자", value: "userCode", category: "text", isFK: true },
  { label: "로트명", value: "lotData.name", category: "text", isFK: false },
  { label: "로트 유효기한", value: "lotData.expiration", category: "date", isFK: false },
  { label: "최종 재고", value: "quantity", category: "number", isFK: false },
];

export const moldsHeader: ColumnHeaderProps[] = [
  { label: "금형코드", value: "code", category: "text", isFK: false },
  { label: "사업장코드", value: "siteCode", category: "text", isFK: true },
];

export const moldStocksHeader: ColumnHeaderProps[] = [
  { label: "금형 재고 코드", value: "code", category: "text", isFK: false },
  { label: "금형코드", value: "moldCode", category: "text", isFK: true },
];

export const abstractItemsHeader: ColumnHeaderProps[] = [
  { label: "품목군 코드", value: "code", category: "text", isFK: false },
];

export const specificationsHeader: ColumnHeaderProps[] = [
  { label: "품목코드", value: "itemCode", category: "text", isFK: false },
  // { label: "검사종류", value: "type", category: "text" },
  // { label: "검사 기준 요약", value: "criteria", category: "text" },
];
export const itemCategoriesHeader: ColumnHeaderProps[] = [
  { label: "품목분류명", value: "name", category: "text", isFK: false },
];

export const downtimeReasonsHeader: ColumnHeaderProps[] = [
  { label: "비가동사유코드", value: "code", category: "text", isFK: false },
  { label: "비가동사유명", value: "name", category: "text", isFK: false },
  { label: "공정코드", value: "operationCode", category: "text", isFK: true },
];

export const workLogHeader: ColumnHeaderProps[] = [
  { label: "품목코드", value: "itemCode", category: "text", isFK: true },
  { label: "수량", value: "quantity", category: "number", isFK: false },
  { label: "투입 로케이션", value: "logData.incoming.locationData.name logData.outgoing.locationData.name", category: "text", isFK: true },
  { label: "설비코드", value: "equipmentCode", category: "text", isFK: true },
  { label: "로트명", value: "logData.incoming.lotData.name logData.outgoing.lotData.name", category: "text", isFK: false },
  { label: "로트 유효기한", value: "logData.incoming.lotData.expiration logData.outgoing.lotData.expiration", category: "date", isFK: false },
  { label: "생산일시", value: "createdAt", category: "date", isFK: false },
  { label: "작업자", value: "creatorUserCode", category: "text", isFK: false },
];

export const purchaseOrderItemsHeader: ColumnHeaderProps[] = [
  { label: "수주 접수일", value: "orderDate", category: "date", isFK: false },
  { label: "납기일", value: "deliveryDeadline", category: "date", isFK: false },
  { label: "품목코드", value: "itemCode", category: "text", isFK: true },
  { label: "수주량", value: "quantityOrdered", category: "number", isFK: false },
  { label: "업체명", value: "contractorName", category: "text", isFK: false },
];

export const deliveryStatusHeader: ColumnHeaderProps[] = [
  { label: "수주번호", value: "purchaseOrderItemId", category: "number", isFK: true },
  { label: "품목코드", value: "itemCode", category: "text", isFK: true },
  { label: "위치", value: "locationCode", category: "text", isFK: true },
  { label: "납품날짜", value: "deliveryDeadline", category: "date", isFK: false },
  { label: "총 납품수량", value: "contractorName", category: "text", isFK: false },
];;

export const wmsLogsHeader: ColumnHeaderProps[] = [
  { label: "수주번호", value: "purchaseOrderItemId", category: "number", isFK: false },
  { label: "출고창고", value: "actionParam.params.sourceLocationCode actionParam.params.locationCode", category: "text", isFK: false },
  { label: "입고창고", value: "actionParam.params.targetLocationCode actionParam.params.locationCode", category: "text", isFK: false },
  { label: "작업자", value: "creatorUserCode", category: "text", isFK: false },
];
