import { useModal } from "@/features/modal/ModalStackManager";
import { MoldDetailForm } from "@/features/mold/form/moldDetailForm";
import { OperationDetailForm } from "@/features/operations/components/form/operationDetailForm";
import { RoutingDetailForm } from "@/features/routing/components/form/RoutingDetailForm";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import styled from "@emotion/styled";
import { Collapse, useMantineColorScheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ItemsGet200ResponseRowsInnerRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export const Tr = styled.tr`
  display: flex;
  width: 100%;
  min-width: 600px;
  align-items: flex-start;
`;

const Td = styled.td<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
`;

const TdIcon = styled(Td)`
  justify-content: center;
  align-self: stretch;
`;

const BomTr = styled(Tr) <{ isDarkMode?: boolean }>`
  padding: 0px 64px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  background: ${(props) =>
    props.isDarkMode ? "var(--violet-0, #f3f0ff)" : "var(--gray-8, #343a40)"};
`;

const BomTd = styled.td`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  border-top-width: 0px;
`;

const BomCollapse = styled(Collapse)`
  width: 45%;
`;
export const RoutingRow = ({
  data,
  children,
}: {
  data: ItemsGet200ResponseRowsInnerRoutingsInner;
  children: ReactNode;
}) => {
  const [opened, { toggle }] = useDisclosure(false);
  const { openModal } = useModal();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";
  const { t } = useTranslation();
  return (
    <>
      <Tr>
        <TdIcon width={4}>
          {data.routingBoms &&
            data.routingBoms.length &&
            data.routingBoms[0].itemCode ? (
            !opened ? (
              <IconPlus onClick={toggle}></IconPlus>
            ) : (
              <IconMinus onClick={toggle}></IconMinus>
            )
          ) : (
            <></>
          )}
        </TdIcon>
        <Td width={9}>{data.seq}</Td>
        <Td width={11}>
          {/* operation Detail */}
          <DetailLink onClick={() => openModal(<OperationDetailForm operationCode={data.operationCode} />, null, "")}>
            {data.operationCode}
          </DetailLink>
        </Td>
        <Td width={13}>
          {/* operaiont Detail */}
          <DetailLink
            onClick={() => openModal(<RoutingDetailForm routingCode={data.code} />, null, "")}
          >
            {data.code}
          </DetailLink>
        </Td>
        <Td width={9}><DetailBadge>{t(data.routingType || '')}</DetailBadge></Td>
        <Td width={9}><DetailBadge>{t(data.consumeType || '')}</DetailBadge></Td>
        <Td width={45}>
          {/* mold Detail */}
          <DetailLink
            onClick={() =>
              openModal(
                <MoldDetailForm moldCode={data.moldCode} />,
                null,
                ""
              )
            }
          >
            {data.moldCode}
          </DetailLink>
        </Td>
      </Tr>
      {data.routingBoms?.length && opened ? (
        <BomTr isDarkMode={isDarkMode}>
          <BomTd rowSpan={7}>
            <BomCollapse in={opened}>{children}</BomCollapse>
          </BomTd>
        </BomTr>
      ) : (
        <></>
      )}
    </>
  );
};
