import { useModal } from "@/features/modal/ModalStackManager";
import { CreateForm } from "@/features/RoutingOutsource/form/CreateForm";
import { RoutingOutsourceTable } from "@/features/RoutingOutsource/table";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const RoutingOutsource = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  CreateForm: CreateForm,
});

export const RoutingOutsourcePage = () => {
  const { openModal } = useModal();

  const publish = usePub();

  return (
    <RoutingOutsource.Wrapper>
      <RoutingOutsource.ActionHeader>
        <RoutingOutsource.ActionButton
          leftIcon={<IconPlus />}
          onClick={() =>
            openModal(
              <RoutingOutsource.CreateForm />,
              null,
              "외주처 추가",
              true
            )
          }
        >
          추가
        </RoutingOutsource.ActionButton>
        <RoutingOutsource.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => publish("deleteSelectedItems")}
        >
          선택된 행 삭제
        </RoutingOutsource.ActionButton>
      </RoutingOutsource.ActionHeader>
      <RoutingOutsource.Header>
        <RoutingOutsource.HeaderTitle>외주처</RoutingOutsource.HeaderTitle>
        <RoutingOutsource.HeaderSubTitle>
          라우팅 외주처를 관리합니다.
        </RoutingOutsource.HeaderSubTitle>
      </RoutingOutsource.Header>
      <RoutingOutsourceTable />
    </RoutingOutsource.Wrapper>
  );
};
