import { useModal } from "@/features/modal/ModalStackManager";
import { OperationsCreateForm } from "@/features/operations/components/form/OperationsCreateForm";
import { OperationsTable } from "@/features/operations/components/table/OperationsTable";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const OperationsWrap = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  CreateForm: OperationsCreateForm,
});

export const Operations = () => {
  const { openModal } = useModal();

  const publish = usePub();

  return (
    <OperationsWrap.Wrapper>
      <OperationsWrap.ActionHeader>
        <OperationsWrap.ActionButton
          leftIcon={<IconPlus />}
          onClick={() =>
            openModal(<OperationsWrap.CreateForm />, null, "공정 추가", true)
          }
        >
          추가
        </OperationsWrap.ActionButton>
        <OperationsWrap.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => publish("deleteSelectedItems")}
        >
          선택된 행 삭제
        </OperationsWrap.ActionButton>
      </OperationsWrap.ActionHeader>
      <OperationsWrap.Header>
        <OperationsWrap.HeaderTitle>공정 리스트</OperationsWrap.HeaderTitle>
        <OperationsWrap.HeaderSubTitle>
          우리 회사에서 다루는 공정에 대해 조회할 수 있는 페이지 입니다.
        </OperationsWrap.HeaderSubTitle>
      </OperationsWrap.Header>
      <OperationsTable />
    </OperationsWrap.Wrapper>
  );
};