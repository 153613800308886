import { mutateItems } from "@/api/items/useItemQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import {
  ItemOption,
  useItemsAutoComplete,
  useItemsCode,
} from "@/features/ui/autoComplete/material/auto-usegetItemQuery";
import { MaterialCreateForm } from "@/features/ui/form/routing/routingBomForm/form";
import styled from "@emotion/styled";
import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { MasterApiItemsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { EventHandler, ReactNode, forwardRef, useState } from "react";
import { useMutation } from "react-query";

interface MaterialProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
  group: string;
  unitText?: string;
}

export interface MaterialAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (MaterialCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
  setUnitText?: (e: string | undefined) => void;
}

export const MaterialAutoComplete = (params: MaterialAutoCompleteProps) => {
  const {
    value: MaterialCode,
    onChange,
    maxDropdownHeight,
    width,
    setUnitText,
    ...etcParms
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useItemsAutoComplete(focused, searchKeyword);

  const { data: initialOptions } = useItemsCode(
    !!MaterialCode,
    MaterialCode ?? null
  );

  let selectedItem = initialOptions?.find(
    (item) => item.value === MaterialCode
  );

  const onChangeHandler = (e: string | null) => {
    selectedItem = options?.find((item) => item.value === e);
    setUnitText && setUnitText(selectedItem?.unitText ?? "");
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, MaterialProps>(
    ({ group, label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs">(품번: {code})</Text>
          </Flex>
          <Text fz="xs">{group}</Text>
        </Flex>
      </div>
    )
  );

  return (
    <Select
      styles={
        MaterialCode
          ? {
            input: {
              width: width,
              fontSize: "0.8em",
              paddingBottom: "0.5rem",
            },
          }
          : { input: { width: width } }
      }
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedItem?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "5px",
              }}
            >
              {"Code: " + selectedItem?.value}
            </div>
          )}
        </div>
      )}
      searchable
      value={MaterialCode}
      itemComponent={SelectItem}
      searchValue={searchKeyword}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: MaterialProps[], option: MaterialProps) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={MaterialInfo({
        item: selectedItem as ItemOption,
        onChange: onChangeHandler,
      })}
      nothingFound={AddNewMaterial({
        itemName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParms}
    />
  );
};

const MaterialInfo = (params: {
  item?: MaterialProps;
  onChange: (itemCode: string | null) => void;
}) => {
  const { item, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return item?.value ? (
    <MaterialInfoLabel>
      {/* ({item.value})  */}
      <IconX size="1rem" onClick={clearHandler} />
    </MaterialInfoLabel>
  ) : null;
};

const AddNewMaterial = (params: {
  itemName: string;
  onChange: (itemCode: string) => void;
}) => {
  const { itemName, onChange } = params;
  const { openModal, closeModal } = useModal();
  const { mutate: itemMutation } = useMutation(
    (params: MasterApiItemsPostRequest) =>
      mutateItems.create(params).mutationFn(params as MasterApiItemsPostRequest | any),
  )

  const onCloseHandler: EventHandler<any> = (values) => {
    itemMutation({
      itemsGetRequest: {
        code: values.code,
        name: values.name,
        itemType: values.itemType,
        itemCategoryName: values.itemCategoryName,
        abstractItemCode: values.abstractItemCode,
        spec: values.spec,
        height: values.height,
        depth: values.depth,
        width: values.width,
        texture: values.texture,
        unitText: values.unitText,
        usesLotName: values.usesLotName,
        usesLotExpiration: values.usesLotExpiration,
      }
    })
    closeModal(values);
  };

  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <MaterialCreateForm name={itemName} onSubmit={onCloseHandler} />,
      null,
      "신규 원부자재 등록",
      true
    ).then((result) => {
      onChange(result.code);
    });
  };
  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 원부자재({itemName}) 등록
    </Button>
  );
};

const MaterialInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;
